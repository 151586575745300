.motivation_block {
  align-items: center;
  background-color: var(--black-haze);
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  width: 100%;
}

.beauty-club365-1 {
  text-align: center;
  width: 1200px;
}

.flex-row-4 {
  align-items: flex-end;
  display: flex;
  margin-right: 56px;
  margin-top: 81px;
  min-width: 1052px;
}

.flex-col-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 380px;
}

.image-6 {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  margin-right: 14px;
  object-fit: cover;
}

.frame-35 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 38px;
  width: 380px;
}

.text-38 {
  text-align: center;
  width: 380px;
}

.text-39 {
  letter-spacing: -0.28px;
  text-align: center;
  margin-top: 18px;
  line-height: 160%;
}

.frame-79-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 89px;
}

.text-40 {
  line-height: 100%;
  margin-bottom: -36px;
  min-height: 64px;
}

.text-41 {
  letter-spacing: -0.64px;
  margin-top: 55px;
  line-height: 120%;
  min-height: 304px;
  width: 583px;
}

.text-42 {
  line-height: 100%;
  margin-bottom: -36px;
  min-height: 64px;
  text-align: right;
  width: 583px;
}

@media screen and (max-width: 700px) {
  .motivation_block {
    top: 620px;
    align-items: center;
  }
  .beauty-club365-1 {
    margin-top: 90px;
    width: 350px;
    font-size: x-large;
  }
  .flex-row-4 {
    flex-direction: column;
    min-width: 100%;
    align-items: center;
    margin: 50px 0;
  }
  .flex-col-2 {
    flex-direction: row;
    margin-left: 50px;
  }
  .image-6 {
    width: 100px;
    height: 100px;
  }
  .frame-35 {
    width: 60%;
    margin-top: 0;
    align-items: flex-start;
  }
  .text-39 {
    font-size: 12px;
    text-align: left;
  }
  .text-38 {
    text-align: unset;
  }
  .frame-79-1 {
    margin-left: unset;
    align-items: center;
  }
  .text-40,
  .text-42 {
    font-size: 40px;
    width: 327px;
  }
  .text-41 {
    width: 80%;
    font-size: 24px;
    margin-top: 25px;
  }
}
@media (min-width: 701px) and (max-width: 1200px) {
  .motivation_block {
    top: 677px;
    padding: 50px 0;
  }
  .beauty-club365-1 {
    font-size: 32px;
    width: 720px;
  }
  .image-6 {
    width: 226px;
    height: 226px;
  }
  .flex-col-2,
  .frame-35 {
    width: 286px;
    align-items: center;
  }
  .text-41 {
    font-size: 24px;
    width: 407px;
  }
  .text-40,
  .text-42 {
    font-size: 52px;
  }
  .text-40 {
    margin-bottom: -63px;
  }
  .text-42 {
    width: 428px;
    margin-top: -47px;
  }
  .frame-79-1 {
    margin-left: 30px;
  }
  .flex-row-4 {
    height: 354px;
    min-width: 734px;
    align-items: center;
  }
}
