.what-you-get_block {
  align-items: center;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.beauty-club365 {
  width: 790px;
  margin-left: 350px;
  margin-top: 100px;
}

.frame-393 {
  display: flex;
  flex-direction: column;
  margin: 50px 0;
  margin-left: 350px;
}

.frame-389 {
  align-items: flex-start;
  display: flex;
  margin-bottom: 25px;
}

.frame-38 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 0px 4px 16px #00000026;
  display: flex;
  flex-direction: column;
  min-height: 175px;
  padding: 16px 0;
  width: 380px;
  margin: 0 10px;
}

.heart {
  height: 25px;
  margin-left: 16px;
  width: 25px;
}

.text-10 {
  align-self: center;
  letter-spacing: -0.36px;
  line-height: 160%;
  margin-top: 7px;
  width: 348px;
}

@media screen and (max-width: 500px) {
  .what-you-get-pointBlock {
    position: absolute;
    top: -172px;
  }
  .frame-38 {
    width: 170px;
    height: 270px;
  }
  .text-10 {
    width: 160px;
    padding: 15px;
    font-size: 14px;
  }
  .frame-393 {
    margin: 50px 0;
  }
  .beauty-club365 {
    margin-top: 50px;
    width: 350px;
    margin-left: 0;
    font-size: x-large;
  }
}
@media (min-width: 500px) and (max-width: 1000px) {
  .frame-393 {
    margin-top: 40px;
    margin: 0;
  }
  .beauty-club365 {
    margin-left: 0;
    margin: 40px 0;
    font-size: 32px;
    width: 471px;
  }
  .frame-38 {
    width: 224px;
    height: 287px;
  }
  .text-10 {
    width: 190px;
  }
}
.what-you-get-pointBlock {
  position: absolute;
  top: -120px;
}
