.courses-content {
  margin-top: 180px;
  width: 1200px;
}
.courses-back-button {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
  color: #8b90a0;
  text-decoration: none;
}
.courses-filter-button-text {
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px;
}
.courses-back-mini-button,
.courses-filter-button-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}
.courses-filter-btn {
  margin-right: 10px;
}
.courses-back-mini-btn {
  transform: rotate(-90deg);
  width: 23px;
  height: 23px;
}
.courses-dropdown-btn {
  transform: rotate(90deg);
}
.courses-h1-pink-text {
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 50px;
  color: #ff059b;
  margin: 20px 0 50px 0;
}
.courses-container {
  display: flex;
  /* flex-direction: column; */
  width: 1200px;
  gap: 20px;
}
.courses-filter {
  width: 35%;
  display: flex;
  flex-direction: column;
}
.courses-filter-box-dropdown {
  padding: 10px 24px;
  /* gap: 30px; */
  width: 278px;
  height: 43px;
  border: 1px solid #cfcce0;
  border-radius: 8px;
  margin-bottom: 20px;
  font-family: "Inter";
  font-size: 16px;
}
.courses-filter-h3 {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  color: #05043a;
  margin-bottom: 20px;
}
.courses-filter-price,
.courses-filter-show-sale-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
.defis-icon {
  width: 18px;
  height: 1px;
  background: #05043a;
}
.courses-filter-price-input {
  width: 120px;
  height: 42px;
  border: 1px solid #cfcce0;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  /* text-align: end; */
}
.courses-filter-price-input::-webkit-input-placeholder {
  font-family: "Inter";
  font-size: 14px;
  color: #8b90a0;
}
/* .courses-filter-show-sale-container {
  display: flex;
} */
.courses-filter-show-sale {
  font-family: "Inter";
  font-size: 16px;
}
.courses-filter-show-sale-checkbox {
  cursor: pointer;
}
.courses-products-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.courses-product-sort {
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  font-family: "Inter";
  font-size: 16px;
  gap: 15px;
}
.courses-filter-sort {
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.courses-product-sort-elem {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  gap: 10px;
  position: relative;
  display: inline-block;
}
.courses-product-sort-elem-select {
  width: auto;
  border: none !important;
  background: unset;
  font-size: 16px;
  color: #05043a;
  text-decoration: none;
  cursor: pointer;
}
.sort-dropdown-btn {
  cursor: pointer;
}
.sort-dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
  width: 143px;
  height: 79px;
  background: #ffffff;
  box-shadow: 0px 10px 25px rgba(209, 209, 209, 0.15);
  border-radius: 8px;
}
.show-sort-dropdown-content {
  display: block;
}
.courses-products {
  grid-template-columns: repeat(auto-fit, minmax(277px, 1fr));
  gap: 20px;
  display: grid;
}
.courses-product {
  display: flex;
  flex-direction: column;
}
.courses-product-img {
  width: 277px;
  height: 277px;
  border-radius: 16px;
  border: 1px solid forestgreen;
  object-fit: cover;
}
.courses-product-h1 {
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
  color: #05043a;
  margin-top: 20px;
  height: 60px;
}
.courses-product-speaker-name {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  color: #05043a;
  margin-top: 20px;
}
.courses-product-theme,
.courses-product-duration,
.courses-product-exercises {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  color: #8b90a0;
  margin-top: 20px;
}
.courses-product-price {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 22px;
  color: #05043a;
  margin-top: 20px;
}
.courses-filter-btn-apply {
  background: #8ebf00;
}
.courses-filter-btn-apply:hover {
  background: #a9e207;
}
.courses-filter-btn-reset {
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  width: 277px;
  height: 46px;
  border: 1px solid #05043a;
  border-radius: 8px;
  color: #05043a;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 20px;
  background-color: transparent;
  cursor: pointer;
}
.courses-filter-btn-reset:hover {
  border: 1px solid #9149f8;
  color: #9149f8;
}
.courses-product-btn-buy {
  background: linear-gradient(
    169.33deg,
    #e335ff -12.42%,
    #ff059b 48.98%,
    #ff60bf 109.12%
  );
  margin-top: 20px;
}
.courses-product-btn-buy:hover {
  background: linear-gradient(
    169.33deg,
    #9149f8 -12.42%,
    #791cff 48.98%,
    #a96efd 109.12%
  );
}
.courses-product-btn-buy,
.courses-filter-btn-apply {
  border: none;
  color: #ffffff;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  padding: 12px 24px;
  gap: 8px;
  width: 277px;
  height: 46px;
  border-radius: 8px;
  cursor: pointer;
}
.courses-product-btn-podrobnee {
  color: #ff059b;
  width: 277px;
  height: 46px;
  text-transform: uppercase;
  background-image: linear-gradient(#e335ff 0%, #ff059b 50.52%, #ff60bf 100%),
    linear-gradient(#e335ff, #ff059b, #ff60bf);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border: 2px solid transparent;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 12px 24px;
  font-family: "Inter";
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
  box-shadow: inset 2px 1000px 1px #fff;
  margin-top: 20px;
  cursor: pointer;
}
.courses-product-btn-podrobnee:hover {
  background: linear-gradient(
    169.33deg,
    #9149f8 -12.42%,
    #791cff 48.98%,
    #a96efd 109.12%
  );
  color: #9149f8;
}
.courses-product-btn-group,
.courses-product-info-text {
  display: flex;
  flex-direction: column;
}
.courses-product-load-more-btn {
  gap: 10px;
  width: 170px;
  height: 42px;
  background: #9149f8;
  border-radius: 8px;
  color: #fff;
  border: none;
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  margin: 0 auto;
}
.courses-product-load-more-btn:hover {
  border: 1px solid #9149f8;
  background: none;
  color: #9149f8;
  cursor: pointer;
}
.courses-product-info {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 769px) {
  .courses-content {
    width: 100%;
    margin-top: 100px;
  }
  .courses-back-mini-button,
  .courses-filter-button-icon,
  .courses-h1-pink-text {
    margin-left: 30px;
  }
  .courses-h1-pink-text {
    margin-bottom: 25px;
  }
  .courses-product-sort {
    display: none;
  }
  .courses-container {
    width: 100%;
    flex-direction: column;
  }
  .courses-filter {
    width: 277px;
    padding-bottom: 30px;
    margin: 0 auto;
  }
  .courses-products-container {
    display: flex;
    align-content: center;
    flex-direction: column;
  }
  .courses-filter-box-dropdown-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .courses-product-btn-group {
    flex-direction: row;
    gap: 20px;
    /* width: 289px; */
    /* padding: 0 30px; */
  }
  .courses-product-info {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 20px;
    justify-content: space-between;
  }
  .courses-product-info-text {
    width: 50%;
  }
  .courses-product-img {
    width: 130px;
    height: 125px;
  }
  .courses-product-duration,
  .courses-product-exercises {
    display: none;
  }
  .courses-product-speaker-name,
  .courses-product-theme {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #05043a;
  }
  .courses-product-h1 {
    margin-top: unset;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    height: auto;
  }
  .courses-product-price {
    font-weight: 500;
    font-size: 18px;
  }
  .courses-products {
    padding: 0 10px;
  }
  .courses-product-btn-podrobnee {
    width: 100%;
  }
}
.loaderWrapper {
  width: 100%;
    text-align: center;
    padding-top: 100px;
}
.course-disabled-apply {
  cursor: default;
  opacity: 0.5;
}
.course-disabled-apply:hover {
  background: #8ebf00;
}
.course-disabled-reset {
  cursor: default;
  opacity: 0.5;
}
.course-disabled-reset:hover {
  border: 1px solid #05043a;
  color: #05043a;
}
.courseContent {
  width: 100%;
  padding: 20px;
  font-family: Inter;
  display: flex;
}
.courseContentTitle {
  font-family: 'Inter';
  text-align: center;
  margin: 30px 0 10px 0;
  font-size: 20px;
  font-weight: bold;
}
.courseContentWrapper {
  width: 500px;
}
.coursesNotFound {
  font-family: 'Inter';
  margin: 20px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}
@media (min-width: 770px) and (max-width: 1200px) {
  .courses-content {
    width: 100%;
    margin-left: 50px;
  }
  .courses-container {
    width: 100%;
  }
  .courses-product-sort {
    width: 467px;
  }
}
@media screen and (max-width: 573px) {
  .courses-product-info-text {
    width: 50%;
  }
}
@media (min-width: 573px) and (max-width: 769px) {
  .courses-product-info {
    height: 180px;
  }
}
