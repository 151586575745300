.navbar_container_mobile {
	color: #fff;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 20px;
    display: block;
    padding-left: 30px;
}
.nav_link_mobile {
	letter-spacing: -.02em;
    cursor: pointer;
    color: #05043A;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    display: block;
    position: relative;
    width: 100%;
    padding: 14px 0;
    background: transparent;
    border: none;
    display: flex;
}
.nav_link_mobile.strong {
    font-weight: 700;
}
.nav_link_mobile:hover {
	text-decoration	: underline;
}
.sepMenu {
    border-bottom: 1px solid #8B90A0;
    width: calc(100% - 30px);
    margin-bottom: 10px;
    padding-bottom: 10px;
}
.sepMenuWrap {
    display: flex;
    margin-bottom: 10px;
}
@media (min-width: 320px) and (max-width: 500px) {
	.navbar_container_mobile {
        height: 91vh;
        overflow-y: scroll;
	}
}
