.what-you-have_block {
  background-color: var(--black-haze);
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  position: relative;
}

.text-108 {
  width: 790px;
  position: absolute;
}

.what-you-have_container {
  min-height: 765px;
  width: 1200px;
  flex-direction: column;
  margin: 160px 0;
  display: flex;
}

.what-you-have-box {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 400px;
}

.rectangle-52 {
  background-color: var(--medium-purple);
  border-radius: 16px;
  height: 16px;
  width: 72px;
}

.text-123 {
  margin-top: 15px;
  min-height: 87px;
  width: 380px;
}

.text-124 {
  letter-spacing: -0.36px;
  line-height: 160%;
  width: 340px;
}

.what-you-have-box {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-right: 20px;
}

.frame-377 {
  align-items: flex-start;
  display: flex;
  margin-bottom: 65px;
  position: relative;
}

@media screen and (max-width: 500px) {
  .text-108 {
    width: 100%;
    text-align: center;
  }
  .what-you-have_block {
    padding-top: 0px;
    margin-top: 0;
  }
  .what-you-have-box {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 15px;
    width: 340px;
    margin-bottom: 20px;
    margin-right: 0;
    height: 290px;
  }
  .text-124,
  .text-123 {
    width: 300px;
  }
  .frame-377 {
    flex-direction: column;
    height: 727px;
    min-width: 0;
  }
  .what-you-have_container {
    width: 100%;
    align-items: center;
    margin-bottom: 0;
  }
  .rectangle-52 {
    width: 53px;
    height: 6px;
  }
  .text-108 {
    margin-top: 70px;
  }
  .what-you-have-pointBlock {
    position: absolute;
    top: -194px;
  }
}

@media (min-width: 500px) and (max-width: 1200px) {
  .what-you-have_block {
    padding-top: 100px;
    margin-top: 0;
  }
  .text-108 {
    font-size: 32px;
    width: 472px;
    margin-top: 30px;
  }
  .what-you-have_container {
    width: 100%;
    margin-left: 70px;
  }
  .frame-377 {
    margin-bottom: 40px;
  }
  .what-you-have-box {
    width: 30%;
  }
  .rectangle-52 {
    height: 10px;
  }
  .text-123 {
    font-size: 20px;
    width: 100%;
  }
  .text-124 {
    font-size: 18px;
    width: 100%;
  }
}

.what-you-have-pointBlock {
  position: absolute;
  top: -142px;
}
