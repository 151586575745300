.certificate_block {
  background-color: var(--stratos);
  width: 100%;
  height: 600px;
  position: relative;
  display: flex;
  justify-content: center;
}
.certificate-container {
  display: flex;
  align-items: center;
}
.certificate-block-content {
  width: 550px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.h1-certificate-container,
.h2-certificate-container {
  margin-bottom: 40px;
  letter-spacing: -0.48px;
}
.h2-certificate-container {
  line-height: 160%;
}
.certificate_block-btn {
  cursor: pointer;
  min-width: 300px;
  color: #fff;
  background: linear-gradient(#e335ff 0%, #ff059b 50.52%, #ff60bf 100%);
  border: 1px;
  border-radius: 100px;
  padding: 24px 32px;
  font-family: "Inter";
  font-size: 16px;
}
.certificate_block-btn:hover {
  background: linear-gradient(
    169.33deg,
    #9149f8 -12.42%,
    #791cff 48.98%,
    #a96efd 109.12%
  );
}
.sert_ill {
  height: 629px;
}
.rectangle38-community_block {
  width: 100%;
  height: 100px;
  position: absolute;
  top: -99px;
}
@media screen and (max-width: 800px) {
  .certificate_block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .certificate-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .certificate-block-content {
    position: inherit;
    width: 100%;
    padding: 0 20px;
    align-items: center;
    text-align: center;
  }
  .sert_ill {
    height: 300px;
    z-index: 1;
    position: relative;
    top: -50px;
  }
  .h1-certificate-container {
    font-size: 24px;
  }
  .h2-certificate-container {
    font-size: 18px;
  }
  .rectangle38-community_block {
    width: 100%;
    height: 50px;
    position: absolute;
    top: -49px;
  }
}
@media (min-width: 800px) and (max-width: 1300px) {
  .certificate-block-content {
    width: 370px;
  }
  .h1-certificate-container {
    font-size: 26px;
  }
  .h2-certificate-container {
    font-size: 20px;
  }
  .sert_ill {
    height: calc(100% - 200px);
  }
}
