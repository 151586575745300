.promotion-line-wrapper {
  width: 100vw;
  position: fixed;
  bottom: 0;
  background: rgba(139, 0, 255, 0.85);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}
.promotion-line-text {
  color: white;
  font-family: 'Inter';
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}
.promotion-line-sale-text {
  background-color: #8ebf00;
  border-radius: 5px;
  padding: 5px;
  white-space: nowrap;
}
.promotion-line-btn {
  border: none;
  background-color: #ff059b;
  color: white;
  display: flex;
  padding: 8px 25px;
  align-items: flex-start;
  border-radius: 16px;
  margin: 0 5px;
  cursor: pointer;
  color: white;
  font-family: 'Inter';
  font-size: 18px;
}
.wrap-1 {
  font-size: 18px;
  text-align: center;
}
.promo-close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  border: none;
  background: none;
  font-size: 30px;
  color: #fff;
}
@media screen and (max-width: 1200px) {
  .promotion-line-wrapper {
    text-align: center;
    flex-direction: column;
    gap: 10px;
  }
  .promotion-line-text {
    line-height: 20px;
    font-size: 12px;
    flex-direction: column;
  }
  .promotion-line-sale-text {
    border-radius: 3px;
    padding: 4px;
  }
}
@media screen and (max-width: 550px) {
  .promotion-line-wrapper {
    padding: 10px;
  }
  .wrap-1, .promotion-line-btn {
    font-size: 14px;
  }
}
