.footer {
  align-items: flex-start;
  display: flex;
  margin: 50px 0;
  width: 1200px;
}

.logo-full-black {
  /* height: 109px; */
  width: 90px;
}

.frame-178 {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  flex-direction: column;
  margin-left: 34px;
  width: 378px;
}

.text-1-45 {
  letter-spacing: -0.28px;
  line-height: 160%;
}

.copyright {
  letter-spacing: -0.28px;
  line-height: 160%;
  margin-top: 8px;
}

.frame-176 {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  min-height: 82px;
  width: 380px;
}

.frame-73 {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--hollywood-cerise);
  display: flex;
  height: 22px;
}

.text-15 {
  letter-spacing: -0.28px;
  line-height: 160%;
  margin-top: -1px;
  min-height: 22px;
  text-decoration: none;
}

.frame-72 {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--hollywood-cerise);
  display: flex;
  margin-top: 8px;
}

.frame-71 {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--hollywood-cerise);
  display: flex;
  margin-top: 8px;
}

.frame-177 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 207px;
}

.frame-74 {
  align-items: flex-start;
  display: flex;
}

.mail,
.phone-call {
  align-self: center;
  height: 16px;
  width: 16px;
}

.supportbeautyclub365ru,
.phone {
  letter-spacing: -0.28px;
  line-height: 160%;
  margin-left: 16px;
  min-height: 22px;
}

.frame-175 {
  align-items: flex-start;
  display: flex;
  margin-top: 8px;
}
.footer-social-media-icons {
  display: flex;
  margin-top: 20px;
  gap: 20px;
}
.footer-social-media-icon {
  width: 30px;
  height: 30px;
}
@media (min-width: 320px) and (max-width: 800px) {
  .logo-full-black,
  .frame-178,
  .frame-177,
  .frame-176 {
    margin-left: 34px;
  }
  .footer {
    width: 100%;
    margin: 50px 0;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }
  .text-1-45,
  .frame-176 {
    padding: 20px 0;
    width: 90%;
  }
  .frame-71 {
    width: inherit;
  }
  .logo-full-black {
    width: 102px;
  }
}
@media (min-width: 800px) and (max-width: 1200px) {
  .frame-178 {
    width: 241px;
  }
  .footer {
    width: 100%;
    margin-bottom: 50px;
    margin-left: 20%;
  }
  .frame-177 {
    margin-left: -380px;
    margin-top: 111px;
  }
}
