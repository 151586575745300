.payment-header {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--stratos);
  box-shadow: 8px 1px 10px var(--stratos);
  opacity: 1;
  justify-content: center;
}
.header-logo {
  width: 102px;
  height: 52px;   
  background-image: url('../../img/optimized/commoin/logo_white.webp');
  position: relative;
  background-size: 100% 100%;
}
.payment-header-container {
  max-width: 1200px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.payment-header-title {
  color: white;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
}
.payment-top-block {
  display: flex;
  align-items: center;
  padding: 45px;
  gap: 30px;
  margin-top: 42px;
  width: 760px;
  background: linear-gradient(275.82deg, rgba(236, 0, 140, 0.8) 0%, rgba(252, 103, 103, 0.7) 99.99%);
  box-shadow: 0px 4px 40px rgba(5, 4, 58, 0.2);
  border-radius: 32px;
}
.payment-top-block-h2 {
  font-family: 'Inter';
  font-weight: 700;
  font-size: 22px;
  line-height: 36px;
  color: #FFFFFF;
}
.payment-top-block-p {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #FFFFFF;
}
.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.payment-container-h1 {
  font-family: 'Inter';
  font-weight: 700;
  font-size: 32px;
  text-transform: uppercase;
  color: #05043A;
  margin-top: 35px;
}
.payment-order-block {
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px;
  gap: 30px;
  width: 1160px;
  background: #FFFFFF;
  box-shadow: 0px 5px 40px rgba(5, 4, 58, 0.2);
  border-radius: 32px;
}
.payment-order-block-first-p, .payment-order-block-first-price {
  margin-bottom: 20px;
}
.payment-order-block-first {
  display: flex;
  flex-direction: column;
  width: 40%;
  border-right: 1px solid #E0E0E0;
  align-items: center;
}
.payment-order-block-first-h2 {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #05043A;
}
.payment-order-block-first-p {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #05043A;
  width: 80%;
}
.payment-order-block-first-price-title {
  font-family: 'Inter';
  font-weight: 700;
  font-size: 26px;
  text-align: center;
  color: #05043A;
}
.payment-order-block-first-price {
  font-family: 'Inter';
  font-weight: 700;
  font-size: 26px;
  text-align: center;
  color: #FF059B;
}
.payment-order-block-second {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
}
.payment-support-block {
  margin: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 50px;
  gap: 10px;
  isolation: isolate;
  width: 760px;
  background: #05043A;
  border: 1px solid #05043A;
  border-radius: 16px;
  position: relative;
}
.payment-support-block-img-1 {
  position: absolute;
  left: -1px;
  top: -1px;
  border-radius: 16px;
}
.payment-support-block-img-2 {
  position: absolute;
  right: -1px;
  bottom: -1px;
  border-radius: 16px;
}
.payment-support-block-h2 {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #FFFFFF;
  z-index: 1;
}
.payment-support-block-h4 {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #FFFFFF;
}
.payment-order-block-support {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 315px;
  border: 1px solid #05043A;
  border-radius: 16px;
}
.payment-order-block-support-text, 
.payment-order-block-support-bold-text {
  font-family: 'Montserrat';
  font-size: 16px;
  line-height: 26px;
  color: #05043A;
  text-align: center;
}
.payment-order-block-support-text {
  font-weight: 400;
}
.payment-order-block-support-bold-text {
  font-weight: 600;
}
.payment-order-block-support-phone {
  display: flex;
}
.payment-order-block-support-phone-p {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  text-decoration-line: underline;
  color: #05043A;
  margin-bottom: unset;
  margin-left: 10px;
  white-space: nowrap;
}
.payment-support-block-phone-img, 
.payment-support-block-email-img {
  width: 25px;
  height: 25px;
}
.payment-support-block-phone-p, 
.payment-support-block-email-p {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  text-decoration-line: underline;
  color: #FFFFFF;
  margin-left: 10px;
  z-index: 1;
  position: relative;
}
.payment-order-block-second-h1 {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 24px;
  color: #05043A;
  text-align: center;
  width: 80%;
}

/* mobile */
@media screen and (max-width: 550px) {
  .payment-header {
    height: 80px;
  }
  .payment-header-container {
    padding: 15px;
  }
  .payment-header-title {
    font-size: 16px;
  }
  .payment-top-block, .payment-order-block, .payment-support-block {
    padding: 25px;
    width: 90%;
    flex-direction: column;
  }
  .payment-order-block-first,
  .payment-order-block-second {
    width: 100%;
  }
  .payment-order-block-first {
    border-bottom: 1px solid #e0e0e0;
    border-right: unset;
  }
  .payment-order-block-support {
    margin-bottom: 30px;
    width: unset;
}
  .payment-container-h1 {
    font-size: 24px;
  }
  .payment-support-block {
    margin: 30px 0;
  }
  .payment-support-block-h2 {
    font-size: 20px;
  }
}

/* tablet */
@media (min-width: 550px) and (max-width: 1200px) {
  .payment-header {
    height: 80px;
  }
  .payment-header-container {
    padding: 15px;
  }
  .payment-header-title {
    font-size: 16px;
  }
  .payment-top-block {
    width: 90%;
  }
  .payment-order-block, .payment-support-block {
    padding: 25px;
    width: 90%;
    flex-direction: column;
  }
  .payment-order-block-first,
  .payment-order-block-second {
    width: 100%;
  }
  .payment-order-block-first {
    border-bottom: 1px solid #e0e0e0;
    border-right: unset;
  }
  .payment-order-block-support {
    margin-bottom: 30px;
  }
  .payment-container-h1 {
    font-size: 24px;
  }
  .payment-support-block {
    margin: 30px 0;
  }
  .payment-support-block-h2 {
    font-size: 20px;
  }
  .payment-top-block-h2 {
    font-size: 20px;
  }
  .payment-top-block-p {
    font-size: 15px;
  }
  .payment-support-block-h4 {
    width: 80%;
  }
}
.notFoundBlock {
  position: relative;
  min-height: 150px;
  width: 100%;
}
.notFoundBlock img {
  position: absolute;
  left: 0;
  top: -18px;
}
.notFoundBlock p {
  color: #05043a;
    font-family: 'Inter';
    font-size: 24px;
    text-align: center;
    line-height: 150px;
}
@media screen and (max-width: 550px) {
  .notFoundBlock img {
    text-align: center;
    margin: 0 auto;
    display: block;
    position: relative;
  }
  .notFoundBlock p {
    line-height: unset;
  }
}
