.results_block {
  width: 1200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 112px;
  position: relative;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 790px;
}

.text-70 {
  text-align: center;
}

.text-71 {
  letter-spacing: -0.48px;
  margin-top: 48px;
  text-align: center;
}

.btn_simple-1 {
  align-items: flex-start;
  background: linear-gradient(
    180deg,
    rgb(227, 53, 255) 0%,
    rgb(255, 5, 155) 50.52%,
    rgb(255, 96, 191) 100%
  );
  border: 1px none;
  border-radius: 100px;
  display: flex;
  height: 70px;
  padding: 23px 32px;
}

.text_label-3 {
  text-transform: uppercase;
  margin-top: 4px;
  letter-spacing: 0.42px;
  min-height: 22px;
  min-width: 229px;
  text-align: center;
}

.image-container-1 {
  flex-direction: column;
  margin-left: 30px;
  display: flex;
}


@media (min-width: 320px) and (max-width: 500px) {
  .results_block {
    width: 100%;
  }
  .text-70 {
    width: 327px;
    font-size: 24px;
    line-height: 29px;
  }
  .text-71 {
    font-size: 18px;
    width: 326px;
  }
  .text-container {
    width: 327px;
  }
  .results-pointBlock {
    position: absolute;
    top: -192px;
  }
}
.results-pointBlock {
  position: absolute;
  top: -140px;
}
