.tariffDetail-container {
  width: 750px;
  height: 70vh;
  padding: 40px;
}
.tariffDetail-h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #05043a;
  text-align: left;
  margin-bottom: 20px;
}
.tariffDetail-description {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #05043a;
}
@media screen and (max-width: 800px) {
  .tariffDetail-container {
    width: 100%;
    height: 100vh;
    overflow: scroll;
    padding: 20px;
  }
  .tariffDetail-h1 {
    font-size: 20px;
    text-align: center;
  }
  .tariffDetail-description {
    font-size: 16px;
  }
}
