.blockProduct {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 0;
}
/* .allProductsWrapper {
  padding: 10px;
} */
.allProductsTitle {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 24px;
  color: #05043a;
  padding: 0 20px;
}
.allProductsFormTitle,
.allProductsFormTitle1 {
  margin: 30px 0;
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}
#green-text {
  color: #8ebf00;
}
#purple-text {
  color: #9149f8;
}
#pink-text {
  color: #ff059b;
}
.ProductWrapper {
  padding: 0 15px;
  border-bottom: 1px solid rgba(217, 217, 217, 0.5);
}
.allProducts-container {
  margin: 30px 0;
}
.prPrice {
  font-family: 'Inter';
  display: block;
  margin: 10px 0 10px 30px;
}
.prPrice strong {
  font-weight: 700;
}
.prLabel {
  color: var(--hollywood-cerise);
  font-family: 'Inter';
  font-weight: 700;
  font-size: 16px;
  display: inline-block;
  margin: 0 10px;
  position: relative;
  top: 0px;
  line-height: 18px;
  cursor: pointer;
}
.prRadio {
  font-family: 'Inter';
  accent-color: #ff059b;
}
.blockProductSub {
  font-family: 'Inter';
  width: 250px;
}
.blockProductSubRadio {
  display: flex;
}
.promoInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px;
  gap: 8px;
  width: 400px;
  height: 55px;
  background: #ffffff;
  border: 1px solid #cfcce0;
  border-radius: 8px;
  font-family: 'Inter';
  font-size: 16px;
}
.promoLabel {
  font-family: 'Inter';
  padding: 0 0 0 20px;
}
.promoButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 100px;
  gap: 10px;
  width: 230px;
  height: 55px;
  background: #ff059b;
  border-radius: 8px;
  color: #fff;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
.promoButton:hover {
  background: #9149f8;
}
.promoWrapper {
  display: flex;
  gap: 10px;
}
.finalPrice {
  font-family: 'Inter';
  font-weight: 700;
  font-size: 20px;
  margin: 20px 0 0 20px;
  display: block;
}
.allProductsTextMobile {
  display: none;
}
.ProductWrapper-mobile {
  display: none;
}
@media screen and (max-width: 764px) {
  .allProductsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ProductWrapper-mobile {
    display: block;
    margin-bottom: -30px;
    border-bottom: none;
    width: 85vw;
  }
  .ProductWrapper-hide {
    display: none;
  }
  .allProductsFormTitle {
    width: 89%;
    margin-top: 30px;
  }
  .allProductsFormTitle1 {
    margin: unset;
    white-space: nowrap;
    color: #05043a;
  }
  .blockProduct {
    flex-direction: column;
    gap: 20px;
    width: 90%;
  }
  .promoWrapper {
    flex-direction: column;
    border-bottom: none !important;
  }
  .promoInput,
  .promoButton {
    width: 80vw;
  }
  .allProductsTextMobile {
    display: block;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #05043a;
    width: 80vw;
    padding: 0 20px;
    margin-top: 10px;
  }
  .allProductsWrapper {
    height: 80vh;
    overflow-y: scroll;
  }
  .blockProductSub {
    width: 80vw;
  }
}
.oldPricePopup {
  text-decoration: line-through;
  font-weight: bold;
  font-size: 14px;
}
.list-group-item {
  background-color: unset !important;
  border: unset !important;
  padding: var(--bs-list-group-item-padding-y) 0 !important;
}
.list-group-item.active {
  background-color: unset !important;
  /* border-color: unset !important; */
  color: unset !important;
}
.list-group-flush > .list-group-item {
  border-width: unset !important;
}
.container {
  padding-right: unset !important;
  padding-left: unset !important;
}
.frame {
  background: #ffffff;
  border: 1px solid #ff059b;
  border-radius: 32px;
  padding: 20px 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}
