.be-speeker-pop-up-content {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.be-speeker-pop-up-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  text-align: center;
}
.be-speeker-pop-up-container {
  position: relative;
  width: 100%;
  height: 1193px;
  background: #ffffff;
  font-family: "Inter";
}
.btn-be-speeker-pop-up {
  border-radius: 32px;
  background: linear-gradient(169.33deg, #e335ff, #ff059b 50.52%, #ff60bf);
  padding: 24px 32px;
  color: white;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  width: 300px;
}
.h1-be-speeker-pop-up {
  font-size: 48px;
  font-weight: 800;
  font-family: "Montserrat";
  color: #05043a;
  margin-bottom: 20px;
}
.h2-be-speeker-pop-up {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 20px;
}
.be-speeker-pop-up-form {
  display: flex;
  margin: 40px 0;
}

.be-speeker-pop-up-right {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 24px;
  width: 483px;
}

.be-speeker-pop-up-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;
  width: 687px;
  margin-left: 20px;
}
.be-speeker-pop-up-left textarea {
  font-family: "Inter";
  font-weight: 400;
  font-size: 18px;
  color: #8b90a0;
}
.btn-be-speeker-pop-up-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.be-speeker-pop-up-dropdown-select {
  width: -webkit-fill-available;
  height: 56px;
  border: 1px solid #cfcce0;
  border-radius: 8px;
  padding: 8px 24px;
  color: #8b90a0;
  font-family: "Inter";
  font-weight: 400;
  font-size: 18px;
}
.be-speeker-pop-up-input-div,
.be-speeker-pop-up-input-div textarea {
  font-family: "Inter";
  font-size: 18px;
  box-sizing: border-box;
  height: 56px;
  background-color: #fff;
  border: 1px solid #cfcce0;
  border-radius: 8px;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  padding: 8px 24px;
  display: flex;
}
.be-speeker-pop-up-radio-div,
.be-speeker-pop-up-radio {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #05043a;
  margin-bottom: 10px;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: url("../../../img/arrow\ to\ right.svg") no-repeat right center;
  outline: 0;
  width: 16.1px;
  background-position-x: calc(100% - 20px);
}
.be-speaker-policy-p {
  text-align: center;
  font-family: "Inter";
  margin: 20px 0;
  font-size: 12px;
  width: 400px;
}
.speakerAddErrors {
  font-family: "Inter";
  margin: 30px 40px;
}
.speakerAddErrors p {
  font-size: 18px;
  font-weight: bold;
  color: #ff051c;
}
.speakerAddErrors ul {
  margin: 5px 0;
}
.speakerAddErrors ul li {
  margin: 3px 0;
  color: #ff051c;
  list-style: circle;
}
.speakerAddSuccess {
  font-family: "Inter";
  margin: 30px 40px;
}
.speakerAddSuccess p {
  font-size: 18px;
  font-weight: bold;
  color: #19a524;
}

@media (min-width: 320px) and (max-width: 1200px) {
  .h1-be-speeker-pop-up {
    font-size: 24px;
    width: 90vw;
  }
  .be-speeker-pop-up-form {
    flex-direction: column;
    align-items: center;
  }
  .be-speeker-pop-up-right,
  .be-speeker-pop-up-left {
    width: 90vw;
  }
  .be-speeker-pop-up-left {
    margin-left: unset;
    margin-top: 24px;
  }
  .btn-be-speeker-pop-up {
    margin-bottom: 20px;
  }
  .be-speaker-policy-p {
    margin-bottom: 50px;
    width: 80%;
  }
  .be-speeker-mobile-height {
    height: 90px;
  }
}
