.register {
  padding: 20px;
}
.registerRow {
  display: flex;
}
.registerNotice {
  width: 500px;
  display: block;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  font-family: Inter;
  color: #7a7878;
}
.registerNotice a {
  color: #7a7878;
}
.beckEndError {
  width: 100%;
  max-width: 99%;
  color: red;
  font-family: Inter;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  margin: 10px auto;
  max-width: 520px;
}
@media screen and (max-width: 600px) {
  .register {
    padding: 20px;
    height: 74vh;
  }
  .registerRow {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .registerNotice {
    width: 100%;
  }
}
