.inputButton {
  width: 301px;
  height: 56px;
  cursor: pointer;
  background: #8ebf00;
  border: 2px;
  border-radius: 100px;
  align-items: center;
  padding: 0 30px;
  text-align: center;
  color: #fff;
  display: block;
  font-size: 15px;
  margin: 20px auto;
}
.inputButton:hover {
  background: #a9e207;
}
.inputButtonDisabled {
  cursor: default;
  background: #979797;
}
.inputButtonDisabled:hover {
  background: #979797;
}
@media screen and (max-width: 764px) {
.inputButton {
  width: 250px;
  }
}
