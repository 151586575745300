.individual-programm_block {
  width: 1200px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.overlap-group10 {
  height: 1040px;
  position: relative;
}

.text-72 {
  left: calc(50% - 575px);
  position: absolute;
  text-align: center;
  top: 127px;
  width: 1200px;
}

.x1-1 {
  height: 690px;
  left: 866px;
  object-fit: cover;
  position: absolute;
  top: 427px;
  width: 562px;
}

.x2 {
  height: 670px;
  left: 1381px;
  object-fit: cover;
  position: absolute;
  top: 382px;
  width: 535px;
}

@media screen and (max-width: 500px) {
  .individual-programm_block {
    margin-left: -678px;
    height: 317px;
  }
  .image-20 {
    position: absolute;
    width: 657px;
    height: 254px;
  }
  .text-72 {
    width: 282px;
    font-size: 20px;
    top: 43px;
    left: 217px;
  }
  .x1-1 {
    top: 184px;
    left: 197px;
    width: 153.89px;
    height: 199.93px;
  }
  .x2 {
    width: 153.89px;
    height: 199.93px;
    top: 166px;
    left: 338px;
  }
}

@media (min-width: 500px) and (max-width: 900px) {
  .x1-1,
  .x2 {
    width: 230.83px;
    height: 299.46px;
  }
  .x1-1 {
    top: 259px;
    left: 191px;
  }
  .x2 {
    top: 230px;
    left: 405px;
  }
  .text-72 {
    font-size: 32px;
    width: 520px;
    top: 68px;
    left: 185px;
  }
  .image-20 {
    width: 1119px;
    height: 434px;
    margin-left: -222px;
  }
  .overlap-group10 {
    height: 486px;
  }
}

@media (min-width: 500px) and (max-width: 1200px) {
  .text-72 {
    font-size: 36px;
    width: 80vw;
    left: calc(50% - 300px);
  }
}
