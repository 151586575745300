.frame-60-1 {
  align-items: center;
  display: flex;
  margin-left: 30px;
  width: 380px;
}

.view-14 {
  background-position: 50% 50%;
  background-size: cover;
  border: 1px none;
  border-radius: 16px;
  height: 64px;
  width: 64px;
}

.text-14 {
  margin-left: 24px;
  width: 292px;
}
@media (min-width: 320px) and (max-width: 700px) {
  .text-14 {
    width: 120px;
    font-size: 14px;
  }
  .frame-60-1 {
    margin-left: 87px;
    width: 200px;
  }
}
@media (min-width: 700px) and (max-width: 950px) {
  .text-14 {
    width: 120px;
    font-size: 14px;
  }
  .frame-60-1 {
    width: 260px;
    margin-left: unset;
  }
  .text-20 {
    width: 250px !important;
  }
}
