.center-logo {
  margin: 30px auto 20px;
}
.formTitle {
  font-family: Inter;
  font-size: 22px;
  font-weight: 700;
  margin: 50px 0 10px 10px;
}
.forgotWrapper {
  padding: 20px;
}
.beckEndSuccess {
  width: 100%;
  max-width: 99%;
  color: #6a8d06;
  font-family: Inter;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  margin: 10px auto;
}
@media screen and (max-width: 500px) {
  .center-logo {
    position: relative;
    left: 0;
  }
}
