.popupWrapper {
  min-height: 100px;
  overflow-y: scroll;
  --ms-overflow-style: none;
  scrollbar-width: none;
  padding: 20px;
}
.basePopup {
  height: 100%;
  width: 100%;
  background-color: rgba(5, 4, 58, 0.6);
  opacity: 0;
  pointer-events: none;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999999;
}
.bp-btn-close-icon {
  position: absolute;
  margin-right: 20px;
  margin-top: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.bp-btn-close-container {
  position: absolute;
  right: 40px;
  z-index: 10;
}
.basePopup.active {
  opacity: 1;
  pointer-events: all;
}
.bp-modal-content {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: 10px;
  max-height: 90vh;
}
@media (min-width: 320px) and (max-width: 500px) {
  /* .bp-modal-content { */
    /* width: 90%; */
    /* max-height: 90%;
    max-width: 90%; */
    /* margin-top: 50px; */
  /* } */
  .bp-btn-close-container {
    position: absolute;
    right: 40px;
  }
}

.test-drive-pop-up-container {
  position: relative;
  width: 1920px;
  height: 1035px;
  overflow: hidden;
  max-width: 90%;
  max-height: 90%;
  text-align: center;
  font-size: 18px;
  color: #05043a;
  font-family: "Inter";
}
.rectangle-div {
  background-color: rgba(5, 4, 58, 0.7);
  height: 2020px;
}
.e-mail-div {
  position: relative;
  line-height: 160%;
  display: inline-block;
}
.input-div {
  align-self: stretch;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #cfcce0;
  box-sizing: border-box;
  height: 56px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: 8px 24px;
  align-items: center;
  justify-content: flex-start;
}
.div1 {
  position: relative;
  letter-spacing: 0.03em;
  line-height: 160%;
  text-transform: uppercase;
  display: inline-block;
}
.btn-projti-test-drive {
  border-radius: 32px;
  background: linear-gradient(169.33deg, #e335ff, #ff059b 50.52%, #ff60bf);
  display: flex;
  flex-direction: row;
  padding: 24px 32px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: 14px;
  color: white;
  cursor: pointer;
  border: none;
}

.btn-projti-test-drive:hover {
  background: linear-gradient(
    169.33deg,
    #9149f8 -12.42%,
    #791cff 48.98%,
    #a96efd 109.12%
  );
}
.span2 {
  text-decoration: underline;
  color: #9149f8;
}
.div2 {
  color: #05043a;
  margin-bottom: 100px;
  font-size: 12px;
  font-family: "Inter";
}
.inputs-projti-test-drive {
  margin-top: 30px;
  width: 456px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  text-align: left;
  color: #8b90a0;
}
.h1-pink-uppercase {
  text-transform: uppercase;
  color: #ff059b;
}
.h1-projti-test-drive {
  margin-top: 100px;
  text-align: center;
  line-height: initial;
  font-size: 48px;
  font-weight: 800;
  font-family: "Montserrat";
  display: inline-block;
  width: 620px;
}
.h2-projti-test-drive {
  margin-top: 30px;
  line-height: 160%;
  font-family: "Inter";
  width: 400px;
}
.btn-close-icon {
  position: absolute;
  margin-right: 30px;
  margin-top: 30px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.btn-close-container {
  display: flex;
  width: 995px;
  flex-direction: row-reverse;
}
.frame-div {
  top: 125px;
  left: 462px;
  background-color: white;
  width: 995px;
  height: 741px;
}
.modal {
  height: 100%;
  width: 100%;
  background-color: rgba(5, 4, 58, 0.4);
  opacity: 0;
  pointer-events: none;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.modal.active {
  opacity: 1;
  pointer-events: all;
}
.modal-content {
  background-color: white;
  width: 995px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

@media screen and (max-width: 600px) {
  .popupWrapper {
    width: 90vw;
    padding: 0;
  }
  .modal-content {
    min-width: 420px;
    height: 1035px;
  }
  .h1-projti-test-drive {
    font-size: 24px;
    width: 300px;
  }
  .inputs-projti-test-drive {
    width: 360px;
  }
  .btn-projti-test-drive {
    border-radius: 58px;
  }
  .btn-close-container {
    width: 418px !important;
    margin-top: 70px;
  }
  .modal {
    align-items: normal;
  }
  .btn-close-icon {
    width: 16px !important;
    height: 16px !important;
  }
}
