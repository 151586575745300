.secret-of-success_block {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.text-82 {
  margin-top: 50px;
  width: 790px;
}

.flex-row-2 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  margin-top: 74px;
  width: 1200px;
}

.flex-col {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 380px;
}

.image-5 {
  height: 300px;
  object-fit: cover;
  width: 300px;
}

.frame-12 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 38px;
  width: 380px;
}

.text-83 {
  text-align: center;
}

.text_label-2 {
  letter-spacing: -0.28px;
  line-height: 160%;
  margin-top: 18px;
  text-align: center;
}

.frame-79 {
  display: flex;
  flex-direction: column;
  margin-left: 87px;
}

.x {
  width: 600px;
}

.span1-1 {
  color: var(--hollywood-cerise);
  font-family: var(--font-family-caveat);
  font-size: var(--font-size-xxl);
  font-weight: 400;
}

.text-99 {
  text-align: right;
}

.frame-27 {
  display: flex;
  flex-direction: column;
}

.rectangle-2 {
  height: 380px;
  object-fit: cover;
  width: 585px;
}

.frame-2-1,
.frame-2-2 {
  align-items: center;
  display: flex;
  margin-top: 80px;
}

.frame-15 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.text-88 {
  width: 483px;
}

.text-89 {
  letter-spacing: -0.36px;
  line-height: 160%;
  margin-top: 24px;
  width: 483px;
}

.rectangle-2-1 {
  height: 380px;
  margin-left: 133px;
  object-fit: cover;
  width: 585px;
}

.frame-14 {
  margin-left: 133px;
}

@media screen and (max-width: 500px) {
  .secret-of-success-pointBlock {
    position: absolute;
    top: -162px;
  }
  .secret-of-success_block {
    align-items: center;
  }
  .text-82 {
    margin-top: 50px;
    width: 350px;
    font-size: x-large;
  }
  .flex-row-2 {
    flex-direction: column;
    width: 327px;
  }
  .flex-col {
    flex-direction: row;
  }
  .image-5 {
    width: 119px;
    height: 119px;
  }
  .frame-12 {
    width: 192px;
    margin-top: 0;
    margin-left: 20px;
    align-items: flex-start;
  }
  .text_label-2 {
    font-size: 12px;
    text-align: justify;
  }
  .frame-79 {
    margin-left: 0;
    margin-top: 20px;
  }
  .x {
    width: 332px;
    font-size: 24px;
  }
  .text-89 {
    font-size: 18px;
  }
  .text-98,
  .text-99 {
    font-size: 40px;
  }
  .frame-2-1 {
    flex-direction: column;
    margin-top: 20px;
  }
  .frame-2-2 {
    flex-direction: column-reverse;
    margin-top: 20px;
  }
  .frame-27 {
    align-items: center;
    width: 100%;
  }
  .frame-15,
  .frame-2-1,
  .frame-2-2,
  .text-88,
  .text-89,
  .rectangle-2-1,
  .rectangle-2 {
    width: 350px;
  }
  .rectangle-2-1,
  .rectangle-2,
  .frame-14 {
    margin: 20px 0;
    margin-left: 0;
    height: 100%;
  }
  .frame-2-1,
  .frame-2-2 {
    border: 1px solid transparent;
    border-bottom: ridge #e6e6e6;
  }
  .frame-2-1-last {
    border: none;
  }
}
@media (min-width: 500px) and (max-width: 1200px) {
  .text-82 {
    width: 471px;
    font-size: 32px;
  }
  .x {
    width: 405px;
    font-size: 24px;
  }
  .image-5 {
    width: 226px;
    height: 226px;
  }
  .frame-12 {
    width: 286px;
  }
  .flex-col {
    width: 286px;
  }
  .flex-row-2 {
    width: 100%;
    justify-content: center;
  }
  .frame-79 {
    margin-left: 25px;
  }
  .rectangle-2-1 {
    width: 348px;
    height: 380px;
    border-radius: 16px;
    margin-left: 40px;
  }
  .rectangle-2 {
    width: 348px;
    height: 380px;
    border-radius: 16px;
  }
  .frame-15,
  .text-89,
  .text-88 {
    width: 348px;
  }
  .text-88 {
    font-size: 24px;
  }
  .text-89 {
    font-size: 18px;
  }
  .frame-14 {
    margin-left: 40px;
  }
  .frame-2-1,
  .frame-2-2 {
    margin-top: 30px;
  }
}
.secret-of-success-pointBlock {
  position: absolute;
  top: -110px;
}
