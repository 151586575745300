.speakerWrapper_container {
  max-width: 800px;
  max-height: 90vh;
}
.speakerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 750px;
  height: 70vh;
  overflow-y: scroll;
}
.imgBack {
  max-width: 250px;
  width: 100%;
  text-align: center;
  margin: 20px auto;
  display: block;
  z-index: 2;
  position: relative;
  border-radius: 250px;
  top: 73px;
  display: block;
  width: 250px;
  height: 250px;
  background-size: cover;
}
.spreakerContent {
  font-family: "Inter";
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  margin: 20px 80px;
  padding-bottom: 30px;
}
.speakerWrapper h1 {
  font-family: "Inter";
  text-align: center;
  font-size: 26px;
  font-weight: 700;
}
.speakerWrapper h1 span {
  color: #ff059b;
}
.speakerBackWrap {
  position: relative;
  margin: 0 50px 150px 0;
}
.speakerBack {
  background-image: url("../../../img/optimized/commoin/speaker-background.webp");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 441px;
  display: block;
  position: absolute;
  top: 20px;
  left: calc(50% - 220px);
  width: 500px;
  height: 500px;
  z-index: 1;
}
.back_btn_container {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  margin-left: -480px;
  cursor: pointer;
}
.back_btn_arrow_icon {
  height: 13px;
}
.back_btn_arrow {
  border: none;
  color: #ff059b;
  background-color: transparent;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
}
.speeker_description {
  font-family: "Inter";
  color: #8b90a0;
  text-align: center;
  margin-top: 20px;
  width: 80%;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
}

@media (min-width: 320px) and (max-width: 500px) {
  .speakerWrapper {
    width: 100%;
    height: 80vh;
  }
  .speakerBack {
    background-size: 300px;
    left: calc(50% - 130px);
    width: 300px;
    height: 300px;
    top: 50px;
  }
  .imgBack {
    width: 230px;
    height: 230px;
    top: 50px;
  }
  .speakerBackWrap {
    margin: 0 50px 57px 0;
  }
  .spreakerContent {
    margin: 20px 22px;
  }
  .back_btn_container {
    margin-left: -180px;
    margin-bottom: 20px;
  }
}
@media (min-width: 501px) and (max-width: 800px) {
  .speakerWrapper {
    width: 100%;
    height: 100vh;
  }
}
@media (min-width: 801px) and (max-width: 1200px) {
  .speakerWrapper {
    width: 80vw;
    height: 80vh;
  }
}
