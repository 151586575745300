.center-logo {
  margin: 30px auto 20px;
}
h2.formTitle {
  font-family: Inter;
  font-weight: 700;
  margin: 10px 0;
  font-size: 18px;
  text-align: center;
}
.forgotWrapper {
  padding: 20px;
}
.beckEndSuccess {
  width: 100%;
  max-width: 99%;
  color: #6a8d06;
  font-family: Inter;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  margin: 10px auto;
}
h1.formTitle {
  color: rgb(234, 36, 145);
  text-align: center;
  font-size: 38px;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 500px) {
  .center-logo {
    position: relative;
    left: 0;
  }
}
