.bc365 {
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
}

._block-container {
  height: 100%;
  position: relative;
  width: 100%;
}

._block-container-1 {
  height: 2800px;
  position: relative;
  width: 100%;
}

.btn-up {
  width: 70px;
  position: fixed;
  bottom: 70px;
  right: 30px;
  cursor: pointer;
}
.joinBtnFixed {
  border: none;
  cursor: pointer;
  color: #ffffff;
  background-color: #ff059b;
  border-radius: 32px;
  padding: 15px 20px;
  position: fixed;
  bottom: 20px;
  z-index: 99;
  width: 90%;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
}
.b24-widget-button-position-bottom-left {
  left: 20px !important;
  bottom: 20px !important;
}

@media screen and (max-width: 500px) {
  .bc365 {
    max-width: 100%;
  }
  ._block-container {
    position: unset;
  }
  ._block-container-1 {
    height: 3725px;
  }
  .btn-up {
    width: 50px;
    bottom: 120px;
  }
}

@media (min-width: 500px) and (max-width: 1200px) {
  .bc365 {
    max-width: 100%;
  }
  ._block-container-1 {
    height: 2164px;
  }
}
@media screen and (max-width: 1200px) {
  .b24-widget-button-position-bottom-left {
    top: 17px !important;
    right: 80px !important;
    z-index: 1000000 !important;
    left: unset !important;
    bottom: unset !important;
  }
  .b24-widget-button-inner-block,
  .b24-widget-button-pulse-animate,
  .b24-widget-button-position-bottom-left.b24-widget-button-wrapper,
  .bx-touch .b24-widget-button-position-bottom-left .b24-widget-button-popup {
    width: 42px !important;
    height: 42px !important;
  }
  .b24-widget-button-inner-mask {
    display: none;
  }
  .b24-widget-button-block {
    width: unset !important;
    height: unset !important;
  }
  .b24-widget-button-pulse-animate {
    -webkit-animation: none !important;
    animation: none !important;
  }
  .b24-crm-button-icon.b24-crm-button-icon-active {
    transform: scale(0.75);
  }
}
