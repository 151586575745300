.promoWrapper {
  margin-bottom: 10px;
}
.promoContainer {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.notActive {
  background: #979797;
    cursor: default;
}
