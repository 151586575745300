.x1 {
  min-width: 100%;
  background-color: var(--stratos);
  border: 1px;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 0px;
  display: flex;
  padding-top: 170px;
  position: relative;
}

.testDrive_btn {
  text-decoration: none;
}

.x1-block-img {
  width: 100%;
  position: relative;
  left: 20px;
  top: 20px;
}

.rectangle-1 {
  top: 1561px;
  width: 100%;
}

.rectangle-13 {
  background-color: var(--stratos);
  border: 1px none;
  top: 0;
  width: 100%;
}

.x1-first-results-block {
  flex-direction: column;
  display: flex;
  position: relative;
  top: 91px;
  left: 309px;
}

.frame-375 {
  display: flex;
  align-items: flex-start;
}

.frame-374 {
  display: flex;
  align-items: flex-start;
  margin-top: 90px;
}

.h1-club-x1 {
  letter-spacing: 0;
  width: 890px;
  position: relative;
  top: -15px;
  left: 18px;
}

.testDrive_btn {
  align-items: center;
  background: #779E00;
  border: 2px none;
  border-radius: 100px;
  display: flex;
  height: 70px;
  width: 320px;
  padding: 0 20px;
  cursor: pointer;
  margin-top: 30px;
}

.testDrive_btn:hover {
  background: #a9e207;
}

.vector-8 {
  padding: 0 10px;
}

.testDrive_btn-text {
  width: 330px;
  align-items: center;
  display: inline-block;
  justify-content: space-between;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
}

.btn-text {
  letter-spacing: 0.48px;
  width: 135px;
}

.h3-x1 {
  width: 380px;
  line-height: 160%;
}

.text-dostupno {
  position: absolute;
  left: 431px;
  top: 780px;
  font-family: 'Inter';
  color: var(--mischka);
}

.h1-x1 {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
}

.h1-pink-text {
  color: var(--hollywood-cerise);
  font-family: var(--font-family-montserrat);
  font-weight: 800;
  /*display: inline-block;*/
}
.h1-white-text {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
}

.butterfly {
  height: 907px;
  width: 620px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

#pink-text {
  color: var(--hollywood-cerise);
}

.frame-371 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 280px;
  margin: 20px;
}

.rectangle-4 {
  background-color: var(--hollywood-cerise);
  border: 1px none;
  border-radius: 16px;
  height: 16px;
  width: 72px;
}

.text-125 {
  letter-spacing: -0.44px;
  line-height: 160%;
  margin: 20px 0 -30px 0;
}

.headerInfoWrapper {
  max-width: 1200px;
  margin: 0 auto;
  z-index: 1;
  display: flex;
  display: none;
}
.headerInfoWrapperText {
  position: relative;
  left: 0;
  z-index: 2;
  width: 40%;
  margin-top: 50px;
}
.headerInfoWrapperImages {
  position: relative;
  width: 60%;
}
.headerinfo-bold-text {
  line-height: 25px;
  display: inline-block;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  margin: 46px 0;
  color: #cfcce0;
}
.color-pink {
  color: var(--hollywood-cerise);
}
.headerinfo-simple-text {
  color: #fff;
  display: inline-block;
  font-family: var(--font-family-inter);
  margin: 4px 0;
  line-height: 20px;
}
.headerinfo-desc-text {
  margin: 12px 0;
}
.headerInfo-group {
  max-width: 1200px;
  z-index: 1;
  margin: 0 auto;
  display: flex;
}

@media screen and (max-width: 500px) {
  .testDrive_btn {
    left: calc(50% - 150px);
    position: relative;
    /* top: 0; */
  }
  .headerinfo-desc-text {
    padding: 0 20px;
  }
  .h1-x1 {
    padding: 0 20px;
    width: 100%;
  }
  .headerInfoWrapperText {
    width: 100%;
  }
  .x1 {
    padding-top: 0;
  }
  .headerInfoWrapper {
    display: block;
  }
  .text_label {
    left: 114px;
    top: 61px;
    width: 295px;
  }
  .h1-x1 {
    width: 300px;
    top: 115px;
    left: 50px;
  }
  .h3-x1 {
    font-size: 18px;
    top: 316px;
    left: 50px;
    width: 310px;
  }
  .h1-white-text,
  .h1-pink-text {
    font-size: 30px;
  }
  .h1-club-x1 {
    width: 388px;
    position: absolute;
    font-size: 24px;
    top: 877px;
    left: 40px;
  }
  .what-you-have_block {
    max-width: 100%;
  }
  .testDrive_btn {
    width: 300px;
    justify-content: center;
    height: 73px;
    padding: 0 20px;
  }
  .btn-text-bold {
    font-size: 20px;
  }
  .btn-text {
    width: 120px;
  }
  .text-dostupno {
    left: 50px;
    top: 552px;
    font-size: 14px;
  }
  .x1-block-img {
    width: 300px;
    top: 600px;
    right: 50px;
  }
  .butterfly {
    width: 80px;
    height: 117px;
  }
  .x1-first-results-block {
    top: 941px;
    left: 25px;
    flex-direction: row;
  }
  .text-125 {
    font-size: 18px;
    width: 140px;
  }
  .rectangle-4 {
    height: 9px;
    width: 83px;
  }
  .frame-371 {
    width: 150px;
    margin: 60px 20px;
  }
  .frame-375,
  .frame-374 {
    flex-direction: column;
  }
  .frame-374 {
    margin-top: unset;
  }
  .text-108 {
    font-size: x-large;
    width: 350px;
    margin-left: 0;
  }
  .x1 {
    margin-top: 80px;
  }
  .headerInfoWrapperImages {
    width: 100%;
  }
  .x1-block-img {
    width: 300px;
    left: calc(50% - 147px);
    position: relative;
    top: 30px;
  }
  .h1-club-x1 {
    width: 100%;
    font-size: 24px;
    position: absolute;
    top: -70px;
    left: 0;
  }
  .x1-first-results-block {
    flex-direction: row;
    top: 100px;
    left: 25px;
  }
  .h1-x1 {
    width: 100%;
  }
  .text-125 {
    color: #fff;
    margin-top: 15px;
  }
  .headerInfo-group {
    margin-bottom: 100px;
  }
  .headerinfo-bold-text {
    margin: 20px 0;
  }
}

@media (min-width: 501px) and (max-width: 1200px) {
  .headerInfoWrapperText {
    margin-left: 250px;
  }
  .x1 {
    top: 80px;
    padding-top: 90px;
  }
  .x1-block-img {
    width: 611px;
    right: -156px;
  }
  .h1-x1 {
    top: 176px;
    left: 25px;
  }
  .h1-white-text,
  .h1-pink-text {
    font-size: 32px;
  }
  .h3-x1 {
    font-size: 20px;
    left: 25px;
    top: 360px;
    width: 300px;
  }
  .testDrive_btn {
    width: 341px;
    height: 64px;
    left: 25px;
    top: 540px;
  }
  .btn-text-bold {
    font-size: 18px;
  }
  .text-dostupno {
    left: 25px;
    top: 619px;
    font-size: 14px;
  }
  .butterfly {
    width: 157px;
    height: 230.05px;
    bottom: 0;
    left: 123px;
  }
  .h1-club-x1 {
    width: 534px;
    font-size: 32px;
  }
  .text-125 {
    font-size: 18px;
  }
  .x1-first-results-block {
    top: 70px;
    left: 0;
  }
  .frame-371 {
    width: 204px;
  }
  .rectangle-4 {
    height: 10px;
  }
  .frame-374 {
    margin-top: 50px;
  }
  .header {
    width: 100%;
    position: fixed;
    box-shadow: 0 4px 8px #00000026;
    background: #fff !important;
  }
  .header-container {
    height: 80px;
    width: 100% !important;
  }
  .navbar_container {
    display: none !important;
  }
}
@media (min-width: 1201px) and (max-width: 1600px) {
  .h1-club-x1 {
    width: 534px;
    font-size: 32px;
  }
  .frame-374 {
    margin-top: 50px;
  }
  .butterfly {
    width: 157px;
    height: 230.05px;
    bottom: 0;
    left: 0;
  }
  .x1-first-results-block {
    top: 70px;
    left: 0;
  }
}

@media screen and (max-width: 500px) {
  .b24-widget-button-social.b24-widget-button-show {
    position: relative;
      top: 160px;
      left: -10px
  }
}


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.swiper {
  width: 1200px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 22px 22px 0 0;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mainSwipper {
  height: 580px;
  width: 1200px;
}

.previewSwipper {
  height: 100px;
  box-sizing: border-box;
  padding: 10px 0;
  border-radius: 0 0 22px 22px;
}

.previewSwipper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.previewSwipper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  background: transparent;
}
.slider-text-preview {
  position: absolute;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  z-index: 1;
}
.slider-text-main {
  text-align: left;
  width: 600px;
  display: block;
  position: relative;
  left: 80px;
  margin-top: 70px;
}
.slider-button {
  text-transform: uppercase;
  border: none;
  border-radius: 32px;
  padding: 16px 32px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  left: 80px;
  display: block;
  margin-top: 30px;
  float: left;
}
.slider-button.second {
  float: left;
  margin-left: 30px;
}
.slider-wrappber {
  position: absolute;
  width: 100%;
  height: 100%;
}
.slider-advertise-text {
  opacity: .7;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 10px;
  position: absolute;
  bottom: 5px;
  left: 80px;
  color: #fff;
}
.previewSwipper .swiper-button-prev, .previewSwipper .swiper-button-next {
  display: none!important;
}
@media screen and (max-width: 500px) {
  .mainSwipper {
    height: calc(100vh - 280px);
    width: 100vw;
  }
  .swiper {
    border-radius: 0px;
    width: 100vw;
  }
  .previewSwipper {
    border-radius: 0px;
  }
  .slider-text-main{
    width: 90%;
    word-wrap: break-word;
    left: 0;
    text-align: center;
    margin: 40px auto;
    padding: 0 30px;
  }
  .slider-button{
    align-self: flex-end;
    margin: 0 auto;
    left: 0;
    position: absolute;
    bottom: 30px;
    left: calc(50% - 95px);
  }
  .slider-button.second{
    bottom: 100px;
    float: initial;
    margin-left: 0;
  }
  .slider-advertise-text{
    position: relative;
    left: 0;
  }
  .slider-wrappber {
    display: grid;
  }
  .slider-advertise-text-wrapper {
    align-self: flex-end;
  }
  .previewSwipper .swiper-slide {
    width: 40%!important;
  }
  .swiper-wrapper{
    width: 100vw!important;
  }
  .slider-text-preview {
    font-size: 11px;
  }
}
