.video-container {
  display: flex;
  width: 1200px;
  flex-wrap: wrap;
  justify-content: center;
}
.video-reviews_container {
  width: 100%;
  background-color: #f6f6f6;
  flex-direction: column;
  align-items: center;
  display: flex;
  padding-bottom: 31px;
}
.text-11-1 {
  width: 790px;
}
.video-reviews_block {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 40px 30px 30px 0;
  display: flex;
  border-radius: 20px;
  height: 209px;
}
.image_video_block {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 40px 30px 30px 0;
  display: flex;
  border-radius: 20px;
  height: 100%;
  cursor: pointer;
}
.overlap-group-container {
  height: 254px;
  position: relative;
  width: 380px;
}
.view-15 {
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 16px;
  position: absolute;
  width: 380px;
}
.btn_play {
  display: flex;
  position: absolute;
  width: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;
  left: 150px;
  top: 170px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
}
.playBtn {
  margin-left: 10px;
  height: 40px;
  width: 40px;
}
.text-1-2 {
  margin-top: 20px;
  line-height: 140%;
  width: 380px;
}
.frame-46-5 {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  height: 29px;
  margin-top: 17px;
  min-width: 380px;
}

.text-1-3 {
  line-height: 160%;
  width: 165px;
}

.text-1-4 {
  line-height: 160%;
  margin-left: 8px;
}
.video-container::-webkit-scrollbar {
  height: 5px;
}
.video-container::-webkit-scrollbar-track {
  background: white;
}
.video-container::-webkit-scrollbar-thumb {
  background: black;
}
.video-container::-webkit-scrollbar-thumb:hover {
  background: #ff059b;
}
.video-reviews-description {
  width: 327px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: #05043a;
}
.video-reviews-name {
  margin-bottom: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 160%;
  color: #05043a;
}
.video-reviews-box {
  display: flex;
  flex-direction: column;
  width: 33%;
}
.load-more-reviews {
  color: white;
  height: 54px;
  min-width: 291px;
  background: linear-gradient(#e335ff 0%, #ff059b 50.52%, #ff60bf 100%);
  border: 1px;
  border-radius: 32px;
  align-items: flex-start;
  margin-top: 48px;
  padding: 15px 24px;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
}

.load-more-reviews:hover {
  background: linear-gradient(
    169.33deg,
    #9149f8 -12.42%,
    #791cff 48.98%,
    #a96efd 109.12%
  );
  color: #fff;
}
@media screen and (max-width: 599px) {
  .video-reviews_block {
    width: 90%;
    height: 183px;
    margin: 40px 0;
  }
  .image_video_block {
    width: 90%;
    height: 90%;
    margin: 40px 0;
    cursor: pointer;
  }
  .video-container {
    width: 100%;
    flex-wrap: nowrap;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: center;
  }
  .load-more-reviews {
    width: 80%;
  }
  .video-reviews-box {
    display: flex;
    align-items: center;
}
}
@media (min-width: 600px) and (max-width: 1000px) {
  .video-reviews_block {
    margin-top: unset;
    height: 168px;
    margin-bottom: 30px;
  }
  .image_video_block {
    margin-top: unset;
    height: 90%;
    margin-bottom: 30px;
    cursor: pointer;
  }
  .video-container {
    flex-direction: column;
    margin-top: 40px;
    align-items: center;
  }
  .video-reviews-box {
    display: flex;
    flex-direction: row;
  }
  .load-more-reviews {
    width: 270px;
  }
}
@media screen and (max-width: 1000px) {
  .text-11-1 {
    font-size: 24px;
    width: 330px;
  }
  .video-reviews_container {
    margin-top: 0;
    padding-bottom: 0;
  }
  .video-reviews-description {
    width: 100%;
  }
  .video-reviews-box {
    width: 100%;
  }
}
@media (min-width: 600px) and (max-width: 1200px) {
  .video-container {
    width: 100%;
  }
}

.image-container {
  display: flex;
  flex-wrap: wrap;
}
/* img {
  width: 200px;
  height: 200px;
  margin: 24px;
  box-shadow: 5px 3px 5px 4px #0000006b;
  border-radius: 5px;
  cursor: pointer;
} */
