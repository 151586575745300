.change-life_block {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 112px;
  width: 1200px;
  position: relative;
}

.h1-change-life {
  text-align: center;
}

.change-life-container {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  position: relative;
}

.change-life-box {
  margin-bottom: 80px;
  align-items: center;
  display: flex;
}

.frame-17 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  width: 608px;
}

.text-75 {
  letter-spacing: -0.36px;
  margin-top: 24px;
  line-height: 160%;
}

@media screen and (max-width: 500px) {
  .change-life-pointBlock {
    position: absolute;
    top: -192px;
  }
  .change-life_block {
    width: 100%;
    margin-top: 0;
  }
  .change-life-container {
    width: 370px;
    margin-top: 40px;
  }
  .h1-change-life {
    margin-top: 50px;
    width: 350px;
    margin-left: 0;
    font-size: 24px;
  }
  .change-life_block-img {
    width: 80px;
    height: 80px;
    margin-bottom: 231px;
  }
  .text-75 {
    width: 330px;
  }
  .frame-17 {
    margin-left: -60px;
  }
  .change-life-h2 {
    margin-left: 80px;
    width: 234px;
    font-size: 20px;
  }
  .change-life-box {
    margin-bottom: 0;
  }
}
@media (min-width: 500px) and (max-width: 1200px) {
  .change-life-container {
    width: 720px;
  }
  .h1-change-life {
    font-size: 40px;
    width: 560px;
  }
}
.change-life-pointBlock {
  position: absolute;
  top: -140px;
}
