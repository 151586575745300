.community-support_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #05043a;
}
.community-support-container {
  display: flex;
  align-items: center;
}
.overlap-group9 {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.rectangle-41 {
  height: 15%;
  width: 100%;
}
.rectangle-4-2 {
  height: 15%;
  width: 100%;
}
.rectangle-39 {
  background-color: var(--stratos);
  border: 1px none;
  height: 430px;
  width: 100%;
}
.text-68 {
  width: 585px;
}
.text-69 {
  width: 500px;
  line-height: 160%;
}
.phone-community-img {
  width: 475px;
  height: 958px;
}
@media screen and (max-width: 700px) {
  .community-support-container {
    flex-direction: column;
    width: 100%;
    /* margin-left: 30px; */
    gap: 40px;
    height: 674px;
  }
  .overlap-group9 {
    gap: 20px;
    flex-direction: column;
  }
  .text-68 {
    line-height: 20px;
    width: 330px;
  }
  .text-68-white,
  .text-68-pink {
    font-size: 24px;
    line-height: 140%;
  }
  .text-69 {
    font-size: 18px;
    width: 327px;
    top: 336px;
    left: 40px;
  }
  .overlap-group1-1 {
    position: relative;
    top: 10px;
    right: 158px;

  }
  .phone-community-img {
    width: 179px;
    height: 361px;
    position: absolute;
  }
}
@media (min-width: 701px) and (max-width: 1200px) {
  .rectangle-19 {
    height: 473px;
  }
  .phone-community-img {
    width: 281.65px;
    height: 568.05px;
  }
  .overlap-group1-1 {
    top: 100px;
    left: 585px;
  }
  .text-68 {
    width: 405px;
    top: 186px;
    left: 46px;
  }
  .text-68-pink,
  .text-68-white {
    font-size: 32px;
  }
  .text-69 {
    top: 460px;
    left: 49px;
    width: 410px;
    font-size: 18px;
  }
  .text-36 {
    font-size: 32px;
  }
  .frame-77 {
    margin-left: -381px;
    margin-top: 98px;
  }
}
