.frame-6 {
  border-bottom: 1px solid #cfcce0;
  width: 1104px;
}
.frame-600 {
  width: 100%;
  align-self: flex-end;
  align-items: center;
  margin-top: 7px;
  margin-right: -49px;
  display: flex;
  position: relative;
  justify-content: space-between;
  padding-bottom: 26px;

}
.text-13 {
  letter-spacing: -0.48px;
  line-height: 160%;
}
.text-130 {
  color: #ff059b;
  font-family: 'Montserrat';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
}
.text-23,
.text-29,
.text-32,
.place {
  letter-spacing: -0.36px;
  line-height: 160%;
  margin-left: 30px;
  width: 200px;
}

.text-20 {
  width: 540px;
  letter-spacing: -0.36px;
  line-height: 160%;
  margin-left: 30px;
}
.text-200 {
  color: #05043A;
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  margin-bottom: 15px;
}
.image-3 {
  height: 40px;
  width: 40px;
}
.opacity {
  opacity: 0;
}

@media (min-width: 320px) and (max-width: 700px) {
  .frame-6 {
    width: 90%;
  }
  .frame-600 {
    flex-direction: column;
    align-self: unset;
    align-items: unset;
    margin-top: 0;
    width: 90%;
    margin-right: -11px;
  }
  .text-13 {
    margin-top: 20px;
  }
  .text-20 {
    margin-top: -35px;
    margin-left: 84px;
    width: 200px;
  }
  .image-3 {
    position: relative;
    top: 55px;
    left: 14px;
  }
}
@media (min-width: 500px) and (max-width: 1200px) {
  .frame-6 {
    align-self: stretch;
    margin-left: 20px;
  }
}
