.text-36 {
  margin-bottom: 60px;
  text-align: center;
}

.frame-59 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 81px;
  width: 1200px;
}

.frame-50 {
  align-items: flex-start;
  display: flex;
  height: 570px;
  position: relative;
}

.frame-51 {
  align-items: flex-start;
  display: flex;
  height: 570px;
  margin-top: 48px;
  min-width: 1200px;
  position: relative;
}

.btn_simple-4 {
  align-items: flex-start;
  background: linear-gradient(
    180deg,
    rgb(227, 53, 255) 0%,
    rgb(255, 5, 155) 50.52%,
    rgb(255, 96, 191) 100%
  );
  border: 1px none;
  border-radius: 32px;
  display: flex;
  height: 54px;
  margin-top: 48px;
  min-width: 281px;
  padding: 15px 24px;
}

.text_label-4 {
  letter-spacing: 0.42px;
  line-height: 160%;
  min-height: 22px;
  text-align: center;
}

.experts_block {
  max-width: 1200px;
  text-align: center;
  margin-top: 100px;
  position: relative;
}

.experts_block .box-experts_block {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
}

.experts_block .box-experts_block .box {
  display: none;
  display: flex;
  flex-direction: column;
}
.experts_block .box-experts_block .box .image-experts_block {
  margin-bottom: 30px;
  overflow: hidden;
  border-radius: 16px;
  align-self: stretch;
  height: 386px;
}

.experts_block .box-experts_block .box .image-experts_block img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.experts_block .box-experts_block .box .content h2 {
  font-size: 20px;
  color: #334;
  white-space: nowrap;
}

.experts_block .box-experts_block .box .content p {
  font-size: 15px;
  color: #676C7E;
  line-height: 2;
  padding: 15px 0;
  letter-spacing: -0.02em;
  height: 125px;
}

.experts_block .box-experts_block .box .content .btn-experts_block {
  display: inline-block;
  border-radius: 32px;
  font-family: "Inter";
  font-size: 14px;
  padding: 12px 24px;
  color: #ff059b;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 2px transparent;
  background-image: linear-gradient(
      rgba(227, 53, 255, 1) 0%,
      rgba(255, 5, 155, 1) 50.52%,
      rgba(255, 96, 191, 1) 100%
    ),
    linear-gradient(180deg, #e335ff, #ff059b, #ff60bf);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  margin-bottom: 30px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}

.experts_block .box-experts_block .box .content .btn-experts_block:hover {
  border: solid 2px #9149f8;
  background: none;
  color: #9149f8;
}

#load-more {
  color: white;
  height: 54px;
  min-width: 291px;
  background: linear-gradient(#e335ff 0%, #ff059b 50.52%, #ff60bf 100%);
  border: 1px;
  border-radius: 32px;
  align-items: flex-start;
  margin-top: 48px;
  padding: 15px 24px;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
}

#load-more:hover {
  background: linear-gradient(
    169.33deg,
    #9149f8 -12.42%,
    #791cff 48.98%,
    #a96efd 109.12%
  );
  color: #fff;
}

.box-experts_block .content {
  position: relative;
}
.experts-pointBlock {
  position: absolute;
  top: -140px;
}
@media (min-width: 501px) and (max-width: 900px) {
  .experts_block .box-experts_block {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(224px, 1fr));
    gap: 10px;
  }
  .experts_block .box-experts_block .box .image-experts_block {
    /* height: 100%; */
    height: unset;
  }
  .experts_block .box-experts_block .box .image-experts_block img {
    height: 224px;
    width: 224px;
    border-radius: 16px;
    align-self: center;
  }
  .experts_block .box-experts_block .box .content {
    width: 224px;
    align-self: center;
  }
  .experts_block .box-experts_block .box .content p {
    height: unset;
  }
}
@media screen and (max-width: 500px) {
  .experts-pointBlock {
    position: absolute;
    top: -112px;
  }
  .experts_block .box-experts_block .box .image-experts_block {
    height: 100%;
  }
  .text-36 {
    font-size: 24px;
    width: 345px;
  }
  .experts_block .box-experts_block {
    /* grid-template-columns: 1fr; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .experts_block .box-experts_block .box {
    flex-direction: row;
    display: flex;
  }
  .experts_block .box-experts_block .box .image-experts_block {
    width: 88px;
    height: 88px;
  }
  .experts_block .box-experts_block .box .content p {
    font-size: 12px;
    width: 238px;
    height: unset;
  }
  .experts_block .box-experts_block .box .content {
    margin-left: 20px;
    text-align: start;
  }
}
