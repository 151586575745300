.inputText {
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  display: block;
  margin: 5px 0;
  font-family: Inter;
  font-size: 16px;
}
.inputText:focus-within {
  outline: none;
}
.inputText:focus {
  border: 1px solid #333;
}
.inputLabel {
  display: block;
  margin: 7px 0;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
}
.inputWrapper {
  margin: 25px 10px 0;
}
.inpurRequired {
  color: #ff059b;
}
.inpurEye {
  opacity: 0.7;
  cursor: pointer;
}
.inpurEye img {
  opacity: 0.7;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 16px;
  width: 20px;
}
.eyeWrapper {
  position: relative;
  max-width: 400px;
}
.inputError {
  font-family: Inter;
  width: 100%;
  display: flex;
  font-size: 13px;
  max-width: 99%;
  color: red;
}
.inputErrorCode {
  font-family: Inter;
  width: 100%;
  display: flex;
  font-size: 13px;
  max-width: 99%;
  color: red;
  max-width: 260px;
  margin: 5px 0 10px 0;
}
.inputFieldError {
  border: 1px solid red !important;
  color: red;
}
.inputNotice {
  font-family: Inter;
  width: 90%;
  display: flex;
  font-size: 13px;
  max-width: 99%;
  color: #7a7878;
}
.phoneConfirmButton {
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  width: 100%;
  height: 40px;
  text-align: center;
  background: linear-gradient(169.33deg, #e335ff, #ff059b 50.52%, #ff60bf);
  border: none;
  border-radius: 8px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Inter;
  font-size: 14px;
  line-height: 40px;
  display: inline-block;
  position: relative;
}
.phoneWaitnigNext {
  box-sizing: border-box;
  color: #fff;
  width: 100%;
  height: 40px;
  text-align: center;
  background: #979797;
  border: none;
  border-radius: 8px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Inter;
  font-size: 14px;
  line-height: 40px;
  display: inline-block;
  position: relative;
  width: 260px;
  margin: 5px 0;
}
.phoneWaitnigNextText {
  font-family: Inter;
  font-size: 13px;
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  width: 260px;
}
.readyConfirmPhone {
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  width: 100%;
  height: 40px;
  text-align: center;
  background: linear-gradient(169.33deg, #e335ff, #ff059b 50.52%, #ff60bf);
  border: none;
  border-radius: 8px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Inter;
  font-size: 14px;
  line-height: 40px;
  display: inline-block;
  position: relative;
}
.confirmedPhone {
  box-sizing: border-box;
  color: #fff;
  cursor: default;
  width: 100%;
  height: 40px;
  text-align: center;
  background: #333;
  border: none;
  border-radius: 8px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Inter;
  font-size: 14px;
  line-height: 40px;
  display: inline-block;
  position: relative;
}
@media screen and (max-width: 624px) {
  .inputText {
    width: 100% !important;
  }
  .eyeWrapper {
    position: relative;
    width: 100% !important;
  }
}
