.forgotPasswort {
  text-decoration: underline;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 25px;
  display: inline-block;
}
.auth {
  padding: 20px;
}
.beckEndError {
  width: 100%;
  max-width: 99%;
  color: red;
  font-family: Inter;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  margin: 10px auto;
}
@media screen and (max-width: 500px) {
  .auth {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
