.schedule_block {
  background-color: var(--black-haze);
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 112px;
  padding: 111px 0;
  display: flex;
  position: relative;
}

.text-18 {
  text-align: center;
  width: 790px;
}

.schedule_container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 81px;
  width: 1200px;
}

.frame-65 {
  align-items: center;
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 0px 4px 16px #00000026;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 31px 0;
  position: relative;
  width: 100%;
}

.address-8 {
  min-height: 29px;
  width: 1104px;
}

.line {
  height: 1px;
  margin-top: 9px;
  width: 1104px;
}

.frame-64 {
  align-items: center;
  background-color: var(--white);
  border: 1px none;
  border-radius: 16px;
  box-shadow: 0px 4px 16px #00000026;
  display: flex;
  flex-direction: column;
  height: 253px;
  margin-top: 48px;
  overflow: hidden;
  padding: 31px 0;
  position: relative;
  width: 1200px;
}

.line-3 {
  height: 1px;
  margin-top: 8px;
  width: 1104px;
}

.frame-63 {
  align-items: center;
  align-self: flex-end;
  border: 1px none;
  display: flex;
  height: 64px;
  margin-right: -49px;
  margin-top: 7px;
  min-width: 1201px;
  position: relative;
}

.text-25 {
  letter-spacing: -0.48px;
  line-height: 160%;
  margin-bottom: 2px;
  min-height: 38px;
  width: 72px;
}

.text-26 {
  letter-spacing: -0.36px;
  line-height: 160%;
  margin-bottom: 2px;
  margin-left: 30px;
  min-height: 58px;
  width: 619px;
}

.image-2 {
  height: 40px;
  margin-left: 30px;
  width: 40px;
}

.frame-66 {
  align-items: center;
  background-color: var(--white);
  border: 1px none;
  border-radius: 16px;
  box-shadow: 0px 4px 16px #00000026;
  display: flex;
  flex-direction: column;
  height: 333px;
  margin-top: 48px;
  overflow: hidden;
  padding: 31px 0;
  position: relative;
  width: 1200px;
}

.btn_simple-9 {
  align-items: flex-start;
  background: linear-gradient(
    180deg,
    rgb(227, 53, 255) 0%,
    rgb(255, 5, 155) 50.52%,
    rgb(255, 96, 191) 100%
  );
  border: 1px none;
  border-radius: 32px;
  display: flex;
  height: 54px;
  margin-top: 48px;
  min-width: 291px;
  padding: 15px 24px;
  text-transform: uppercase;
  cursor: pointer;
}

.btn_simple-9:hover {
  background: linear-gradient(
    169.33deg,
    #9149f8 -12.42%,
    #791cff 48.98%,
    #a96efd 109.12%
  );
  color: #fff;
}

.text_label-5 {
  letter-spacing: 0.42px;
  line-height: 160%;
  min-height: 22px;
  min-width: 243px;
  text-align: center;
}
.margin-t-10 {
  margin: 20px 0;
}

@media (min-width: 320px) and (max-width: 700px) {
  .schedule-pointBlock {
    position:absolute;
    top: -132px;
  }
  .text-18 {
    text-align: center;
    width: 330px;
    font-size: 24px;
  }
  .schedule_container {
    width: 330px;
    margin-top: 40px;
  }
  .schedule_block {
    padding: 35px 0;
    margin-top: 50px;
  }
  .address-8 {
    min-height: 43px;
    width: 272px;
  }
  .line {
    width: 90%;
  }
}
@media (min-width: 700px) and (max-width: 1200px) {
  .schedule_container {
    width: calc(100% - 200px);
  }
  .text-18 {
    text-align: center;
    width: 330px;
    font-size: 24px;
  }
  .schedule_block {
    padding: 35px 0;
    margin-top: 50px;
  }
  .address-8 {
    min-height: 43px;
    width: 100%;
    text-align: start;
    margin-left: 72px;
  }
  .line {
    width: 90%;
  }
}
.schedule-pointBlock {
  position:absolute;
  top: -80px;
}
