.center-logo {
  margin: 30px auto 20px;
}
.wrapper-auth-reg {
  border: 1px solid #c5c5c5;
  width: 400px;
  margin: 50px auto 10px;
  display: flex;
  border-radius: 8px;
  height: 50px;
}
.wrapper-form.active {
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  width: 50%;
  height: 40px;
  text-align: center;
  background: linear-gradient(169.33deg, #e335ff, #ff059b 50.52%, #ff60bf);
  border: none;
  border-radius: 8px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Inter;
  font-size: 14px;
  line-height: 40px;
  display: inline-block;
  position: relative;
}
.wrapper-form {
  box-sizing: border-box;
  color: #f701c2;
  cursor: pointer;
  width: 50%;
  height: 40px;
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 8px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Inter;
  font-size: 14px;
  line-height: 40px;
  display: inline-block;
  position: relative;
}
.sub-wrapper-auth-reg {
  width: 100%;
  display: flex;
  margin: 4px 8px;
}
.loaderWrapper {
  margin: 50px auto;
  width: 100%;
  text-align: center;
  min-width: 300px;
  min-height: 100px;
  vertical-align: middle;
  top: 50%;
  position: relative;
}

@media screen and (max-width: 500px) {
  .wrapper-auth-reg {
    width: 90%;
    max-width: 260px;
  }
  .center-logo {
    position: relative;
    left: 0;
  }
}
