.header {
  width: 100%;
  z-index: 20;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--stratos);
  box-shadow: 8px 1px 10px var(--stratos);
  opacity: 1;
}
.header-container {
  max-width: 1200px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}
.logo-icon {
  background-image: url('../../img/optimized/commoin/logo_white_5years.webp');
  position: relative;
  width: 0;
  height: 0;
  padding-left: 102px;
  padding-top: 52px;
  background-size: 100% 100%;
  top: 20px;
  color:transparent;
}
.logo-icon-dark {
  background-image: url('../../img/optimized/commoin/logo_black_5years.webp');
  position: relative;
  width: 0;
  height: 0;
  padding-left: 102px;
  padding-top: 52px;
  background-size: 100% 100%;
  /* top: 20px; */
}
.gamburger-menu-mobile {
  display: none;
  cursor: pointer;
}
.btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  gap: 20px;
  position: relative;
}
.wanna_be_speeker_btn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 20px;
  gap: 8px;
  border: 1px solid #ffffff;
  border-radius: 32px;
  background: none;
  cursor: pointer;
}
.wanna_be_speeker_btn:hover {
  background: #a9e207;
  border: 1px solid #a9e207;
}
.text_wanna_be_speeker_btn {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  white-space: nowrap;
  color: #ffffff;
  text-decoration: none;
}
.log_in_btn,
.buy_certificate_btn {
  cursor: pointer;
  padding: 16px 32px;
  background: linear-gradient(
    169.33deg,
    #e335ff -12.42%,
    #ff059b 48.98%,
    #ff60bf 109.12%
  );
  border-radius: 32px;
  border: none;
}
.log_in_btn:hover,
.buy_certificate_btn:hover {
  background: linear-gradient(
    169.33deg,
    #9149f8 -12.42%,
    #791cff 48.98%,
    #a96efd 109.12%
  );
}
.text_log_in_btn,
.text_buy_certificate_btn {
  text-transform: uppercase;
  color: #fff;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}
.logoSeparator {
  display: inline-block;
  height: 100%;
  width: 1px;
  background: #fff;
}
.logoAvatar {
  max-width: 50px;
  border-radius: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  -o-object-position: top;
  object-position: top;
  width: 50px;
  height: 50px;
}
.logoTitle {
  color: #fff;
  font-family: 'Inter';
  cursor: pointer;
}
.userMenu {
  z-index: 23;
  background: #fff;
  border-radius: 7px;
  padding: 10px 20px;
  position: absolute;
  top: 100px;
  right: 0px;
  box-shadow: 1px 1px 6px #dcdcdd;
}
.userMenu a {
  color: #05043a;
  padding: 10px 0;
  font-family: 'Inter';
  text-decoration: none;
  display: block;
}
.userMenu a.bold {
  font-weight: 700;
  border-bottom: 1px solid rgba(139, 144, 160, 0.7);
}
.textAvatar {
  color: #fff;
  background: linear-gradient(
    169.33deg,
    #e335ff -12.42%,
    #ff059b 48.98%,
    #ff60bf 109.12%
  );
  border-radius: 60px;
  font-family: 'Inter';
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 24px;
}

@media screen and (max-width: 500px) {
  .header {
    background: #ffffff !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    position: fixed;
    width: 100%;
    opacity: 1 !important;
    z-index: 600000;
  }
  .header-container {
    height: 80px;
    width: 100% !important;
  }
  .logo-icon {
    background-image: url('../../img/optimized/commoin/logo_black_5years.webp') !important;
    width: 58px;
    left: 30px;
    top: 10px;
  }
  .logo-icon-dark {
    background-image: url('../../img/optimized/commoin/logo_black_5years.webp') !important;
    width: 58px;
    left: 30px;
    top: 10px;
  }
  .btn-container {
    display: none !important;
  }
  .gamburger-menu-mobile {
    display: unset;
    width: 35px;
    height: 35px;
    margin-right: 30px;
    margin-top: 20px;
  }
  .btn-up {
    width: 50px;
    bottom: 20px;
    right: 20px;
  }
  .textAvatar {
    display: block;
  }
  .logoTitle {
    color: #05043a;
    line-height: 60px;
    margin-left: 20px;
    font-size: 20px;
    cursor: default;
  }
}

@media (min-width: 500px) and (max-width: 1200px) {
  .logoTitle {
    color: #05043a;
    line-height: 60px;
    margin-left: 20px;
    font-size: 20px;
    cursor: default;
  }
  .header {
    background: #ffffff !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    position: fixed;
    width: 100%;
    opacity: 1 !important;
  }
  .header-container {
    height: 80px;
    width: 100% !important;
  }
  .logo-icon {
    background-image: url('../../img/optimized/commoin/logo_black_5years.webp') !important;
    width: 58px;
    left: 30px;
    top: 10px;
  }
  .logo-icon-dark {
    background-image: url('../../img/optimized/commoin/logo_black_5years.webp') !important;
    width: 58px;
    left: 30px;
    top: 10px;
  }
  .btn-container {
    display: none !important;
  }
  .gamburger-menu-mobile {
    display: unset;
    width: 35px;
    height: 35px;
    margin-right: 30px;
    margin-top: 20px;
  }
  .btn-up {
    width: 50px;
    bottom: 20px;
    right: 20px;
  }
}
