.payment-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  position: relative;
}
.payment-block-h1 {
  width: 1200px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 59px;
  text-align: center;
  margin-top: 20px;
}
.btn_simple-2-1 {
  align-items: flex-start;
  background: #8ebf00;
  border: 1px none;
  border-radius: 32px;
  display: flex;
  height: 54px;
  margin-top: 24px;
  min-width: 231px;
  padding: 15px 24px;
  cursor: pointer;
}
.btn_simple-2-1:hover {
  background: #a9e207;
}
.text-7 {
  letter-spacing: 0.42px;
  margin-top: 5px;
  min-height: 22px;
  min-width: 183px;
  text-align: center;
  text-transform: uppercase;
}
@media (min-width: 791px) and (max-width: 1200px) {
  .payment-block-h1 {
    width: 90%;
    font-size: 34px;
    line-height: 38px;
  }
}
@media (min-width: 961px) and (max-width: 1200px) {
  .payment-block-container {
    min-width: 100% !important;
    justify-content: center !important;
    gap: 18px !important;
  }
  .payment-block-price-box {
    height: 245px !important;
  }
  .payment-block-more,
  .payment-block-join {
    width: 250px !important;
  }
  .payment-block-box {
    width: 300px !important;
  }
  .payment-block-box-price-container {
    width: 264px !important;
  }
  .payment-block-box-period {
    font-size: 17px !important;
  }
  .payment-block-box-price {
    font-size: 20px !important;
  }
  .payment-block-box-header {
    width: 300px !important;
  }
  .payment_block {
    width: 100%;
    align-items: center;
  }
  .text-43 {
    width: 602px;
    font-size: 32px;
    margin-top: 20px;
  }
  .btn_simple-2-1 {
    padding: 15px;
    min-width: 208px;
    height: 46px;
    align-items: center;
  }
}
.payment-pointBlock {
  position: absolute;
  top: -150px;
}
@media screen and (max-width: 790px) {
  .payment-pointBlock {
    position: absolute;
    top: -202px;
  }
}
.payment-block-container {
  min-width: 1200px;
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 28px;
  margin-top: 50px;
}
.payment-block-box {
  width: 380px;
  background: #2a295b;
  box-shadow: 0px 5px 20px rgba(5, 4, 58, 0.2);
  border-radius: 16px;
}
.payment-block-box-header {
  width: 380px;
  height: 100px;
  left: 0px;
  top: 0px;
  border-radius: 16px 16px 0 0;
}
.payment-block-box-header-green {
  background: #779E00;
}
.payment-block-box-header-pink {
  background: #ff059b;
}
.payment-block-box-header-purple {
  background: #9149f8;
}
.payment-block-box-header-h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
  position: relative;
  top: 16px;
}
.payment-block-box-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.payment-block-box-h2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 15px;
}
.payment-block-box-p {
  padding: 0 25px 25px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-align: start;
  color: #ffffff;
  height: 220px;
  width: 90%;
}
ul {
  list-style: disc !important;
}
.payment-block-price-box {
  height: 267px;
}
.payment-block-box-price-container {
  width: 340px;
  height: 76px;
  /* margin-bottom: 25px; */
}
.payment-block-box-price-container:nth-child(2) {
  margin-top: 20px;
}
.payment-block-box-price-container::after {
  content: '________________________________';
  color: #ffffff;
  display: block;
  white-space: nowrap;
  overflow: hidden;
}
.payment-block-box-period {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #ffffff;
  float: left;
  margin-right: 10px;
}
.payment-block-box-price {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #ffffff;
  float: right;
  margin-left: 10px;
}
.payment-block-box-economy {
  text-align: right;
  width: -webkit-fill-available;
  color: #8ebf00;
  font-family: 'Montserrat';
  font-size: 18px;
  font-style: normal;
  line-height: 22px;
  position: relative;
  top: -25px;
}
.payment-block-more,
.payment-block-join {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  width: 300px;
  height: 55px;
  border-radius: 32px;
  cursor: pointer;
  border: none;
}
.payment-block-more {
  background: linear-gradient(
    169.33deg,
    #e335ff -12.42%,
    #ff059b 48.98%,
    #ff60bf 109.12%
  );
  margin: 25px 0;
}
.payment-block-join {
  background: #AAE600;
  margin-bottom: 30px;
}
.payment-block-more:hover {
  background: linear-gradient(
    169.33deg,
    #9149f8 -12.42%,
    #791cff 48.98%,
    #a96efd 109.12%
  );
}
.payment-block-join:hover {
  background: #a9e207;
}
.payment-block-box-link {
  color: #8ebf00;
  text-align: center;
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  margin: 20px;
  cursor: pointer;
}
.promo-description {
  font-size: 24px;
  font-family: 'Inter', sans-serif;
  margin-top: 10px;
  text-align: center;
  width: 80%;
}
.promo-description-mobile {
    display: none;
}
@media (min-width: 791px) and (max-width: 1200px) {
  .payment-block-container {
    min-width: unset;
    gap: 13px;
  }
  .payment-block-box {
    width: 265px;
  }
  .payment-block-box-header {
    width: 265px;
    height: 80px;
  }
  .payment-block-box-header-h1 {
    font-size: 20px;
    line-height: 24px;
  }
  .payment-block-box-h2 {
    font-size: 20px;
    line-height: 20px;
  }
  .payment-block-box-p {
    font-size: 16px;
    line-height: 26px;
    padding: 0px 20px;
    height: 220px;
  }
  .payment-block-box-period,
  .payment-block-box-price {
    font-size: 18px;
  }
  .payment-block-box-price-container {
    width: 240px;
    height: 75px;
  }
  .payment-block-more,
  .payment-block-join {
    width: 200px;
    height: 45px;
  }
  .payment-block-price-box {
    height: 230px;
  }
  .payment-block-box-economy {
    font-size: 16px;
  }
}
@media screen and (max-width: 790px) {
  .payment-block {
    margin-top: 40px;
  }
  .payment-block-h1 {
    font-size: 24px;
    width: 295px;
    line-height: 30px;
  }
  .payment-block-container {
    align-items: center;
    flex-direction: column;
    margin-top: 24px;
  }
  .payment-block-box-header-h1 {
    font-size: 22px;
  }
  .payment-block-box-h2 {
    font-size: 20px;
    margin-bottom: -15px;
    margin-top: -10px;
  }
  .payment-block-box-p {
    height: 267px;
    padding: 25px;
    font-size: 17px;
  }
  .payment-block-box {
    width: 320px;
  }
  .payment-block-box-header {
    width: 320px;
  }
  .payment-block-box-period {
    font-size: 18px;
  }
  .payment-block-box-price {
    font-weight: 600;
    font-size: 20px;
  }
  .payment-block-box-economy {
    top: -17px;
  }
  .payment-block-box-price-container {
    width: 284px;
    height: 75px;
  }
  .payment-block-price-box {
    height: 220px;
  }
  .payment-block-more,
  .payment-block-join {
    width: 280px;
  }
  .payment-block-more {
    margin: 34px 0 15px 0;
  }
  .promo-description {
    display: none;
  }
  .promo-description-mobile {
    display: block;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 0;
  }
}
.oldPrice {
  text-decoration: line-through;
  font-weight: normal;
  font-size: 20px;
  color: #ff60bf;
}
