.be-speaker_block {
  width: 100%;
  position: relative;
  display: flex;
  background-color: var(--hollywood-cerise);
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
}

.be-speaker-container {
  width: 1200px;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: center;
}

.rectangle-49 {
  width: 100%;
}

.be-speaker-h-container {
  width: 585px;
}

.be-speaker-h2,
.be-speaker-h1,
.be-speaker-h3 {
  margin-bottom: 40px;
}

.be-speaker-h3 {
  letter-spacing: -0.48px;
  line-height: 160%;
}

.be-speaker-btn {
  cursor: pointer;
  text-transform: uppercase;
  background-color: #608000;
  border-radius: 100px;
  padding: 24px 32px;
  position: absolute;
  border: none;
}

.be-speaker-btn:hover {
  background-color: #a9e207;
}

.be-speaker-btn-text {
  letter-spacing: 0.42px;
  text-align: center;
}

.overlap-group-4 {
  width: 837px;
}

.image-26 {
  height: 240px;
  left: 382px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 240px;
}

.group-367 {
  align-items: flex-start;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  left: 188px;
  min-height: 673px;
  padding: 186.3px 0;
  position: absolute;
  top: 48px;
  transform: rotate(10.38deg);
  width: 593px;
}

.rectangle-7-1 {
  background-color: var(--medium-purple);
  border: 1px none;
  border-radius: 22px;
  height: 42px;
  margin-left: -64.9px;
  transform: rotate(-45deg);
  width: 544px;
}

.rectangle-8-1 {
  background-color: var(--medium-purple);
  border: 1px none;
  border-radius: 22px;
  height: 42px;
  margin-left: -20.18px;
  margin-top: 23px;
  transform: rotate(-45deg);
  width: 544px;
}

.rectangle-9-1 {
  align-self: center;
  background-color: var(--medium-purple);
  border: 1px none;
  border-radius: 22px;
  height: 42px;
  margin-left: 0;
  margin-top: 23px;
  transform: rotate(-45deg);
  width: 544px;
}

.rectangle-10-1 {
  align-self: flex-end;
  background-color: var(--medium-purple);
  border: 1px none;
  border-radius: 22px;
  height: 42px;
  margin-right: -20.18px;
  margin-top: 23px;
  transform: rotate(-45deg);
  width: 544px;
}

.rectangle-11-1 {
  align-self: flex-end;
  background-color: var(--medium-purple);
  border: 1px none;
  border-radius: 22px;
  height: 42px;
  margin-right: -64.9px;
  margin-top: 23px;
  transform: rotate(-45deg);
  width: 544px;
}

.vector-12 {
  height: 541px;
  left: 0;
  position: absolute;
  top: 24px;
  width: 650px;
}

.image-24 {
  height: 282px;
  left: 149px;
  object-fit: cover;
  position: absolute;
  top: 91px;
  width: 282px;
}

.image-25 {
  height: 425px;
  left: 315px;
  object-fit: cover;
  position: absolute;
  top: 240px;
  width: 425px;
}
.be-speaker-btn-text {
  text-decoration: none;
}
@media (min-width: 350px) and (max-width: 400px) {
  .be-speaker-btn {
    font-size: 11px;
  }
}
@media (min-width: 320px) and (max-width: 350px) {
  .be-speaker-btn {
    font-size: 9px;
  }
}
@media screen and (max-width: 900px) {
  .be-speaker-container {
    width: 100%;
    flex-direction: column;
  }
  .overlap-group-4 {
    width: 372px;
    margin-bottom: 20px;
    margin-top: 40px;
  }
  .be-speaker-h-container {
    width: 100%;
    padding: 0 46px;
    text-align: center;
    margin-top: 30px;
  }
  .be-speaker-h1 {
    font-size: 24px;
  }
  .be-speaker-h2 {
    font-size: 20px;
  }
  .be-speaker-h3 {
    font-size: 18px;
  }
  .be-speaker-btn {
    width: 306px;
    background-color: #05043a;
    position: relative;
  }
}
@media (min-width: 901px) and (max-width: 1200px) {
  .be-speaker-container {
    width: 100%;
  }
  .overlap-group-4 {
    width: 500px;
  }
  .be-speaker-h1 {
    font-size: 32px;
  }
  .be-speaker-h2 {
    font-size: 24px;
    width: 90%;
  }
  .be-speaker-h3 {
    font-size: 20px;
    width: 90%;
  }
  .be-speaker-h2,
  .be-speaker-h1,
  .be-speaker-h3 {
    margin-bottom: 20px;
  }
}
