.navbar_container {
	color: white;
	width: 1200px;
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding-top: 25px;
	padding-bottom: 20px;
}
.nav_link {
	position: relative;
	letter-spacing: -0.02em;
	display: inline-block;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	cursor: pointer;
	color: #fff;
	text-decoration: none;
	background: transparent;
    border: none;
}
.nav_link:hover {
	text-decoration	: underline;
}
@media (min-width: 200px) and (max-width: 1200px) {
	.navbar_container {
		display: none;
	}
}