:root { 
  --black: #000000;
  --black-haze: #f6f6f6;
  --cultured-pearl: #f5f5f5;
  --hollywood-cerise: #ff059b;
  --hollywood-cerise-bold: #D60081;
  --manatee: #8b90a0;
  --manatee-bold: #676c7e;
  --medium-purple: #9149f8;
  --mischka: #cfcce0;
  --sheen-green: #8dbf00;
  --stratos: #05043a;
  --white: #ffffff;
 
  --font-size-l: 22px;
  --font-size-m: 18px;
  --font-size-s: 14px;
  --font-size-xl: 24px;
  --font-size-xxl: 32px;
  --font-size-xxxl: 48px;
  --font-size-xxxxl: 80px;
 
  --font-family-caveat: "Caveat", Helvetica;
  --font-family-inter: "Inter", Helvetica;
  --font-family-montserrat: "Montserrat", Helvetica;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #ff059b;
  box-shadow: 0 0 1px rgba(255,255,255,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.montserrat-extra-bold-stratos-18px {
  color: var(--stratos);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 800;
}

.inter-normal-manatee-14px {
  color: var(--manatee-bold);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.inter-normal-stratos-18px {
  color: var(--stratos);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.inter-medium-white-14px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.montserrat-extra-bold-stratos-24px {
  color: var(--stratos);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 800;
}

.montserrat-extra-bold-stratos-48px {
  color: var(--stratos);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 800;
}

.inter-normal-mischka-22px {
  color: var(--mischka);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.montserrat-extra-bold-hollywood-cerise-32px {
  color: var(--hollywood-cerise);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 800;
}

.montserrat-extra-bold-white-18px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 800;
}

.montserrat-extra-bold-hollywood-cerise-18px {
  color: var(--hollywood-cerise);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 800;
}

.inter-normal-hollywood-cerise-24px {
  color: var(--hollywood-cerise);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.montserrat-extra-bold-white-48px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 800;
}

.montserrat-extra-bold-hollywood-cerise-64px {
  color: var(--hollywood-cerise);
  font-family: var(--font-family-montserrat);
  font-size: 64px;
  font-style: normal;
  font-weight: 800;
}

.montserrat-extra-bold-hollywood-cerise-24px {
  color: var(--hollywood-cerise);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 800;
}

.montserrat-extra-bold-stratos-32px {
  color: var(--stratos);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 800;
}

.montserrat-medium-stratos-32px {
  color: var(--stratos);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 500;
}

.inter-normal-black-haze-14px {
  color: var(--black-haze);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.montserrat-extra-bold-black-haze-32px {
  color: var(--black-haze);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 800;
}

.montserrat-medium-white-32px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-stratos-24px {
  color: var(--stratos);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 500;
}

.inter-normal-manatee-18px {
  color: var(--manatee);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.caveat-normal-stratos-32px {
  color: var(--stratos);
  font-family: var(--font-family-caveat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 400;
}

.montserrat-extra-bold-hollywood-cerise-48px {
  color: var(--hollywood-cerise);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 800;
}

.inter-normal-white-24px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.inter-normal-stratos-14px {
  color: var(--stratos);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.montserrat-extra-bold-hollywood-cerise-80px {
  color: var(--hollywood-cerise);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 800;
}

.inter-medium-white-16px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.inter-black-stratos-14px {
  color: var(--stratos);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 900;
}

.inter-black-white-24px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
}

.montserrat-extra-bold-white-40px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
}

.inter-normal-stratos-24px {
  color: var(--stratos);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.inter-normal-white-18px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.inter-normal-mischka-24px {
  color: var(--mischka);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.montserrat-extra-bold-white-32px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 800;
}
