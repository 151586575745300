.community_block {
  width: 100%;
  position: relative;
  background-color: #837abd;
}
.h1-community {
  display: flex;
  flex-direction: column;
}
.h1-community_block {
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 3vw;
  line-height: 78px;
  position: absolute;
  text-align: start;
  width: 40%;
  top: 35%;
  left: 20%;
  color: #ffffff;
}
.pics-community_block {
  width: 100%;
}
.rectangle50-community_block {
  position: absolute;
  top: -1px;
  width: 100%;
}
@media screen and (max-width: 500px) {
  .h1-community_block {
    font-size: 6vw;
    line-height: 29px;
    text-align: center;
    width: 80%;
    top: 15%;
    left: 10%;
  }
  .pics-community_block {
    width: 160%;
    margin-top: calc(100% - 259px);
    margin-left: -210px;
  }
  .rectangle38-community_block {
    height: 50px;
    top: 528px;
  }
}
@media (min-width: 500px) and (max-width: 1200px) {
  .h1-community_block {
    font-size: 4vw;
    line-height: 39px;
    top: 30%;
  }
}
