.cpWrapper,
.cpWrapper1 {
  display: flex;
  cursor: pointer;
  width: 50%;
  flex-shrink: 0;
  padding-right: 30px;
  box-sizing: border-box;
  margin-bottom: 30px;
  align-items: center;
}
.cpWrapper2 {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.cpWrapper3 {
  width: 100%;
  font-size: 13px;
  font-weight: 700;
}
.buyFormTitle {
  text-align: center;
  margin-bottom: 30px;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 24px;
  color: #05043A;
}
.cpRadio[type=radio] {
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}
.cpLabel {
  font-family: "Inter";
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-left: 15px;
  cursor: pointer;
  text-align: start;
  color: #FF059B;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  gap: 20px;
}
.cpPrice {
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 7px;
}
.cpTarriff {
  font-family: "Inter";
  margin-bottom: 7px;
  font-size: 13px;
  color: #333;
  position: relative;
  top: 5px;
}
.cpAgree {
  font-family: "Inter";
  position: relative;
  top: -2px;
}
.cpAgree a {
  text-decoration: none;
  color: var(--hollywood-cerise);
}
.cpNotice {
  font-family: "Inter";
  text-align: center;
  display: block;
  font-size: 13px;
  color: #333;
}
.cpNotice a {
  color: var(--hollywood-cerise);
}
.buyWrapper {
  max-width: 600px;
  padding: 30px;
}
.cpWrapperMain {
  display: flex;
  margin: 30px 0;
  flex-wrap: wrap;
}
.cpImage {
  margin-bottom: 10px;
  max-height: 30px;
}
.cpImage-tinkoff {
  height: 30px !important;
}
@media screen and (max-width: 650px) {
  .cpWrapper,
  .cpWrapper1 {
    display: flex;
    cursor: pointer;
    width: 100%;
    flex-shrink: 0;
    padding-right: 30px;
    box-sizing: border-box;
    margin-bottom: 30px;
    font-size: 13px;
  }
  .cpLabel {
    margin: 10px;
    font-size: 14px;
  }
  .cpImage {
    height: 50px;
  }
  .cpWrapperMain {
    flex-direction: column;
  }
  .buyWrapper {
    overflow: scroll;
    height: 90vh;
  }
}
